import { AlertType, UserAccountAlertDto } from '@generatedTypes/data-contracts';
import { ProfilePictureAlertRow } from '@components/AlertCenter/AlertRows/userAccount/ProfilePictureAlertRow';
import { TermsAndConditionsAlertRow } from '@components/AlertCenter/AlertRows/userAccount/TermsAndConditionsAlertRow';

export const UserAccountAlertRow = ({ alert }: { alert: UserAccountAlertDto }) => {
  if (alert.alertType === AlertType.MissingProfilePicture) {
    return <ProfilePictureAlertRow />;
  }

  if (alert.alertType === AlertType.TermsAndConditionsNotAccepted) {
    return <TermsAndConditionsAlertRow />;
  }

  console.error(`UserAccountAlertRow: alertType ${alert.alertType} is not supported`);

  return null;
};
