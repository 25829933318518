import { useGetUser } from '@services/api/users';
import { Link, Stack, Typography } from '@mui/material';
import { ProfilePictureAvatar } from '@components/ProfiePictureAvatar';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { mdiChevronDown } from '@mdi/js';
import { useSignOut } from '@services/api/authenticate/signOut';
import { useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router';

export const UserProfile = () => {
  const {
    translate,
    translations: {
      myAccount: { header },
      common: { buttons },
    },
  } = useTranslations();
  const { user } = useGetUser();
  const [isDropdownOpen, toggleIsDropdownOpen] = useToggle(false);
  const { signOut } = useSignOut();
  const currentRoute = useLocation().pathname;

  const isActive = currentRoute.includes(`/my-account`);

  const onLogOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Stack justifyContent="space-between" direction="row">
      <Link href={`/my-account`} underline="none" sx={{ flexGrow: 1 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={(theme) => ({
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
            },
            backgroundColor: isActive ? theme.palette.rexelEnergy.blackBlue : `transparent`,
            color: isActive ? theme.palette.common.white : theme.palette.text.primary,
          })}
        >
          <ProfilePictureAvatar />
          <Stack direction="column" justifyContent="center" alignItems="flex-start">
            <Typography variant="h3" color="inherit">{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography variant="subtitle1" color="inherit">
              {translate(header)}
            </Typography>
          </Stack>
        </Stack>
      </Link>
      <Dropdown
        isDropdownOpen={isDropdownOpen}
        toggleIsDropdownOpen={toggleIsDropdownOpen}
        iconButtonIconPath={mdiChevronDown}
        styles={{
          alignSelf: `flex-start`,
          '&.MuiButtonBase-root': { gap: 3.5 },
          '& svg': { color: `inherit` },
        }}
      >
        <MenuItem onClick={onLogOut}>{translate(buttons.logOut)}</MenuItem>
      </Dropdown>
    </Stack>
  );
};
