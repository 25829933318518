import { AlertReturnType } from '@services/api/alerts/alerts';
import { InboxAlertDto, PartnerSettingsAlertDto, AlertScope, UserAccountAlertDto } from '@generatedTypes/data-contracts';

export const alertIsInboxAlertDto = (alert: AlertReturnType): alert is InboxAlertDto => alert.scope === AlertScope.Inbox;

export const alertIsPartnerSettingsAlertDto = (alert: AlertReturnType): alert is PartnerSettingsAlertDto =>
  alert.scope === AlertScope.PartnerSettings;

export const alertIsUserAccountAlertDto = (alert: AlertReturnType): alert is UserAccountAlertDto =>
  alert.scope === AlertScope.UserAccount;
