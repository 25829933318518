import { AlertRow } from '@components/AlertCenter/AlertRows/AlertRow';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useNavigate } from 'react-router-dom';
import { ProfilePictureAvatar } from '@components/ProfiePictureAvatar';

export const ProfilePictureAlertRow = () => {
  const {
    translate,
    translations: {
      alertCenter: { myAccount },
    },
  } = useTranslations();

  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/my-account?modal=userProfilePicture`);
  };

  return (
    <AlertRow
      Icon={<ProfilePictureAvatar />}
      title={translate(myAccount.profilePicture.title)}
      actionText={translate(myAccount.profilePicture.path)}
      subtitle={translate(myAccount.subtitle)}
      action={goToProfile}
    />
  );
};
