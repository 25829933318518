import { mdiBellBadgeOutline, mdiBellOutline } from '@mdi/js';
import { buttonMediumSize } from '../../styles/theme/theme';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { useTheme } from '@mui/material';
import { useGetAlerts } from '@services/api/alerts/alerts';
import { Alerts } from '@components/AlertCenter/AlertRows/Alerts';

export const AlertCenter = () => {
  const [isFilterDropdownOpen, toggleIsFilterDropdownOpen] = useToggle();
  const { alerts } = useGetAlerts();

  const theme = useTheme();

  return (
    <Dropdown
      iconButtonIconPath={alerts?.length ? mdiBellBadgeOutline : mdiBellOutline}
      isDropdownOpen={isFilterDropdownOpen}
      toggleIsDropdownOpen={toggleIsFilterDropdownOpen}
      placeIconAfterText={true}
      styles={{
        padding: 1.5,
        minWidth: buttonMediumSize,
        height: buttonMediumSize,
        minHeight: buttonMediumSize,
        '& svg': {
          color: theme.palette.text.primary,
        },
      }}
      transformLeft
    >
      <Alerts alerts={alerts ?? []} />
    </Dropdown>
  );
};
