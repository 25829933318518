import { useDispatch, useSelector } from 'react-redux';

import { DropdownValue } from '@components/controls/dropdown-control';
import { useFetchDealer } from './useFetchDealer';
import { selectSelectedDealerId, setSelectedDealerId } from '@redux/reducers/slices/dealerUser';
import { mdiChevronDown } from '@mdi/js';
import MenuItem from '@mui/material/MenuItem';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { useTheme } from '@mui/material';

type SelectDealersProps = {
  toggleIsDrawerOpen?: () => void;
};

export const SelectDealers = ({ toggleIsDrawerOpen }: SelectDealersProps) => {
  const selectedDealerIdFromStore = useSelector(selectSelectedDealerId);
  const { isDealerUser, isLoading, values } = useFetchDealer();
  const [isDealerSelectDropdownOpen, toggleDealerSelectDropdownOpen] = useToggle();
  const theme = useTheme();

  const dispatch = useDispatch();
  const selectedDealerText = values.find((value) => value.value === selectedDealerIdFromStore)?.label;

  if (!values.length || !isDealerUser) {
    return null;
  }

  const handleClick = (dropdownValue: DropdownValue) => {
    dispatch(setSelectedDealerId(dropdownValue.value));
  };

  return (
    <Dropdown
      buttonText={selectedDealerText}
      isDropdownOpen={isDealerSelectDropdownOpen}
      toggleIsDropdownOpen={toggleDealerSelectDropdownOpen}
      iconButtonIconPath={mdiChevronDown}
      disabled={isLoading}
      styles={{
        alignSelf: `flex-start`,
        '&.MuiButtonBase-root': { gap: 3.5 },
        '& svg': { color: theme.palette.rexelEnergy.blackBlue },
      }}
    >
      {values.map((option) => {
        return (
          <MenuItem
            key={option.value}
            onClick={() => {
              handleClick(option);
              toggleDealerSelectDropdownOpen();
              toggleIsDrawerOpen?.();
            }}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};
