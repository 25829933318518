import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Card } from '@components/Card/Card';
import { TermsAndConditionsModal } from '@components/TermsAndConditionsModal/TermsAndConditionsModal';

export const TermsAndConditions = () => {
  const {
    translate,
    translations: { termsAndConditionsModal },
  } = useTranslations();

  return (
    <Card title={translate(termsAndConditionsModal.header)}>
      <TermsAndConditionsModal />
    </Card>
  );
};
