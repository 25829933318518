import { SystemSettingsDto } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { Drawer } from '@mui/material';
import {
  SettingsTemplateFieldsType,
  useUpdateSettingsTemplates2,
} from '@services/api/settingsTemplates/settingsTemplates';
import { SystemSettingsEdit } from './SystemSettingsEdit';
import { OfferSettingsCard } from './OfferSettingsCard';

export interface OfferSettingsCardProps {
  title: string;
  settingsTemplates: SystemSettingsDto | null | undefined;
  templateToEdit: SettingsTemplateFieldsType;
}

export const OfferSettingsCardWithEdit = ({ title, settingsTemplates, templateToEdit }: OfferSettingsCardProps) => {
  const [isEditOpen, toggleIsEditOpen] = useToggle();
  const updateSystemSettings = useUpdateSettingsTemplates2();

  return (
    <>
      <OfferSettingsCard
        title={title}
        richTextAsHtml={settingsTemplates?.[templateToEdit] || ``}
        titleAction={toggleIsEditOpen}
      />
      <Drawer open={isEditOpen} onClose={toggleIsEditOpen} anchor="right">
        <SystemSettingsEdit
          disableForm={updateSystemSettings.isLoading}
          updateSystemSettings={updateSystemSettings.mutate}
          systemSettings={settingsTemplates}
          templateToEdit={templateToEdit}
          goBackHandler={toggleIsEditOpen}
        />
      </Drawer>
    </>
  );
};
