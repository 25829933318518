export const CREATE = `create`;
export const UPDATE = `update`;
export const DELETE = `delete`;

export const USER = `user`;
export const USERS = `users`;

export const PARTNER = `partner`;
export const PARTNER_LOGO = `partner-logo`;
export const PROJECT_ADDITION_TEMPLATES = `project-addition-templates`;
export const DEDUCTION_TEMPLATES = `deduction-templates`;
export const PARTNERS = `partners`;

export const DEALER = `dealer`;
export const DEALERS = `dealers`;

export const FORGOT_PASSWORD = `forgot-password`;
export const RESET_PASSWORD = `reset-password`;

export const SOLAR_ENERGY_PROJECT = `solar-energy-project`;
export const SOLAR_ENERGY_PROJECT_TEMPLATES = `solar-energy-project-templates`;
export const SOLAR_ENERGY_PROJECT_SETTINGS = `solar-energy-project-settings`;
export const SOLAR_ENERGY_PROJECT_MOUNTING_MATERIAL_MANUFACTURERS = `solar-energy-project-mounting-material-manufacturers`;

export const ENERGY_STORAGE_PROJECT = `energy-storage-project`;

export const OFFER = `offer`;

export const OFFER_AGREEMENT = `offer-agreement`;
export const OFFER_ATTACHMENTS = `offer-attachments`;
export const OFFER_BASKET_ITEMS = `offer-basket-items`;
export const OFFER_BASKET_ITEMS_HASH = `offer-basket-items-hash`;
export const BASKET_ITEMS = `basket-items`;

export const LEADS = `leads`;
export const LEAD = `lead`;

export const PROJECTS = `projects`;
export const PROJECT_PRODUCT = `project-product`;
export const PROJECT_ADDITION = `project-addition`;
export const PROJECT_DEDUCTION = `project-deduction`;

export const OFFER_PRICE_OVERVIEW = `offer-price-overview`;

export const OFFER_BIDS = `offer-bids`;
export const OFFERS_BIDS = `offers-bids`;

export const CAR_CHARGING_PROJECT = `car-charging-project`;
export const CAR_CHARGING_PROJECT_SETTINGS = `car-charging-project-settings`;
export const CAR_CHARGING_PROJECT_TEMPLATES = `car-charging-project-templates`;

export const SUBSCRIPTIONS = `subscriptions`;

export const EMPTY_PROJECT = `empty-project`;

export const PRODUCT_CATEGORIES = `product-categories`;

export const PRODUCT_PACKAGE = `product-package`;

export const SYSTEM_SETTINGS_TEMPLATES = `system-settings-templates`;

export const SYSTEM_MESSAGE = `system-message`;

export const CONTACT_DATA = `contact-data`;

export const PRODUCT_MANAGEMENT_PRODUCTS = `product-management-products`;

export const PRODUCT_MANAGEMENT_PRODUCT_CATEGORIES = `product-management-product-categories`;

export const MANAGEABLE_PRODUCT = `manageable-product`;

export const MANAGEABLE_PRODUCT_PACKAGE = `manageable-product-package`;

export const ALERTS = `alerts`;

export const TERMS_AND_CONDITIONS = `terms-and-conditions`;
