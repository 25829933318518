import { ColumnItem } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { SystemSettingsDto, UpdateSystemSettingsRequest } from '@generatedTypes/data-contracts';
import { SettingsTemplateFieldsType } from '@services/api/settingsTemplates/settingsTemplates';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { useForm } from 'react-hook-form';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';

type SystemSettingsEditProps = {
  disableForm: boolean;
  goBackHandler: () => void;
  updateSystemSettings: (systemSettings: UpdateSystemSettingsRequest) => void;
  systemSettings?: SystemSettingsDto | null;
  templateToEdit: SettingsTemplateFieldsType;
};

export const SystemSettingsEdit: ColumnItem<SystemSettingsEditProps> = ({
  disableForm,
  goBackHandler,
  systemSettings,
  updateSystemSettings,
  templateToEdit,
}) => {
  const {
    translate,
    translations: {
      systemSettings: { systemSettingsInfoCards },
    },
  } = useTranslations();

  const titleTranslation = systemSettingsInfoCards[templateToEdit];

  const { handleSubmit, reset, control } = useForm({
    defaultValues: { [templateToEdit]: systemSettings?.[templateToEdit] ?? `` },
  });

  const onCancel = useCallback(() => {
    goBackHandler();
    reset();
  }, [goBackHandler, reset]);

  if (!titleTranslation) {
    console.error(`No translation found for ${templateToEdit}`);
    return null;
  }

  return (
    <FormWrapper
      onCancel={onCancel}
      onSubmit={handleSubmit((values) => {
        if (systemSettings) {
          updateSystemSettings({
            ...systemSettings,
            ...values,
          });
          goBackHandler();
          reset();
        }
      })}
      disabled={disableForm}
    >
      <FormSection title={translate(titleTranslation)}>
        <RichTextInputWrapper label="" control={control} name={templateToEdit} />
      </FormSection>
    </FormWrapper>
  );
};
