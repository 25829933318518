import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import { useToggle } from '@hooks/useToggle';
import { useGetCarChargingProjectSettings } from '@services/api/carChargingProjects/carChargingProjectsSettings';
import { useGetCarChargingProjectTemplates } from '@services/api/carChargingProjects/carChargingProjectsTemplates';
import { useGetLeads } from '@services/api/leads/leads';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useGetSolarEnergyProjectTemplates } from '@services/api/solarEnergyProjects/solarEnergyProjectsTemplates';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeftNavLinkLoaderList } from '@components/LeftNavLink/LeftNavLinkLoaderList';
import { useMediaQuery } from '@mui/material';
import { theme } from 'src/styles/theme/theme';
import { useTranslatedLeadStatus } from './utils';
import { useSelector } from 'react-redux';
import { selectIsOperationUser, selectIsPartnerUser } from '@redux/selectors/user';
import { selectIsDealerUser } from '@redux/reducers/slices/user';
import { LeadsLeftNavLink } from './LeadsLeftNavLink';
import { selectChosenLanguage } from '@redux/selectors/global';
import { PackageDetailsModalFromStore } from '@components/PackageDetailsModal/PackageDetailsModalFromStore';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';
import { LeadsTitleComponents } from '@pages/NewLeads/LeadsTitleComponenets';

export const Leads = () => {
  const params = useParams<{ leadId: string }>();
  const {
    translations: {
      common,
      leads: {
        list: { header, expiresPrefix },
        details: { leadOfferStatuses, customerDetails },
      },
    },
    translate,
  } = useTranslations();
  const getTranslatedLeadStatus = useTranslatedLeadStatus({ ...leadOfferStatuses }, translate);
  const [leadsFilter, setLeadsFilter] = useState<LeadOfferStatus | undefined>();
  const { leadsList, isLoadingLeadsList } = useGetLeads();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const [leftLink, setLeftLink] = useState<HTMLAnchorElement | null>(null);
  const [isFirstLoad, toggleIsFirstLoad] = useToggle(true);
  const isOperationUser = useSelector(selectIsOperationUser);
  const isDealerUser = useSelector(selectIsDealerUser);
  const isPartnerUser = useSelector(selectIsPartnerUser);
  const currentLanguage = useSelector(selectChosenLanguage);
  const { openModal } = useQueryParamsModal(`lead`);

  useGetSolarEnergyProjectSettings();
  useGetSolarEnergyProjectTemplates();
  useGetCarChargingProjectSettings();
  useGetCarChargingProjectTemplates();

  const leads = useMemo(
    () => (leadsFilter ? leadsList.filter((lead) => lead.status === leadsFilter) : leadsList),
    [leadsFilter, leadsList],
  );

  useEffect(() => {
    if (leftLink) {
      leftLink.scrollIntoView({ block: `center` });
      toggleIsFirstLoad();
      setLeftLink(null);
    }
    // watch only for leftLink
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLink]);

  useEffect(() => {
    const param = params.leadId;
    const isParamInLeads = leads.some((lead) => String(lead.id) === param);
    const locationIsLeads = location.pathname.endsWith(`leads`);

    if (!isMobile && leads?.[0]?.id && !isParamInLeads && locationIsLeads && !isLoadingLeadsList) {
      navigate(`/leads/${leads?.[0]?.id}`);
    }
  }, [isMobile, leads, leads.length, location.pathname, navigate, params.leadId, isLoadingLeadsList]);

  const onNewClick = () => {
    openModal({ mode: `create` });
  };

  const isLoading = !leadsList.length && isLoadingLeadsList;

  return (
    <>
      <ColumnLayout
        list={
          isLoading
            ? LeftNavLinkLoaderList
            : leads.map((lead) => (
                <LeadsLeftNavLink
                  customerDetails={customerDetails}
                  getTranslatedLeadStatus={getTranslatedLeadStatus}
                  isDealerUser={isDealerUser}
                  isFirstLoad={isFirstLoad}
                  isOperationUser={isOperationUser}
                  isPartnerUser={isPartnerUser}
                  key={lead.id}
                  lead={lead}
                  commonTranslations={common}
                  paramLeadId={params.leadId}
                  setLeftLink={setLeftLink}
                  translate={translate}
                  currentLanguage={currentLanguage}
                  expiresPrefix={translate(expiresPrefix)}
                />
              ))
        }
        title={translate(header)}
        titleComponents={<LeadsTitleComponents leadsFilter={leadsFilter} setLeadsFilter={setLeadsFilter} />}
        onNewClick={onNewClick}
        scrollTopOnNewListElement
      >
        <Outlet />
      </ColumnLayout>
      <PackageDetailsModalFromStore />
    </>
  );
};
