import { useGetUser } from '@services/api/users';
import { Avatar } from '@mui/material';
import Icon from '@mdi/react';
import { mdiCameraPlusOutline } from '@mdi/js/commonjs/mdi';

export const ProfilePictureAvatar = () => {
  const { user } = useGetUser();

  return user?.logotypeUri ? (
    <Avatar
      sx={(theme) => ({
        height: theme.spacing(6),
        width: theme.spacing(6),
        maxWidth: theme.spacing(6),
      })}
      alt="Profile picture"
      src={user?.logotypeUri}
    />
  ) : (
    <Avatar
      sx={(theme) => ({
        height: theme.spacing(6),
        width: theme.spacing(6),
        maxWidth: theme.spacing(6),
        '& svg': {
          color: theme.palette.rexelEnergy.ctaBlue,
        },
        backgroundColor: theme.palette.common.white,
      })}
    >
      <Icon path={mdiCameraPlusOutline} size={1} />
    </Avatar>
  );
};
