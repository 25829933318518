/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AdditionPriceDto {
  name: string;
  /** @format double */
  netPrice: number;
  isDeductible: boolean;
}

export interface AddressDto {
  street: string;
  zipCode: string;
  city: string;
}

export interface AlertDto {
  scope: AlertScope;
  alertType: AlertType;
}

export enum AlertScope {
  Global = 'Global',
  UserAccount = 'UserAccount',
  PartnerSettings = 'PartnerSettings',
  Inbox = 'Inbox',
}

export enum AlertType {
  None = 'None',
  MissingProfilePicture = 'MissingProfilePicture',
  MissingPartnerLogotype = 'MissingPartnerLogotype',
  MissingMargin = 'MissingMargin',
  MissingHourlyRate = 'MissingHourlyRate',
  TermsAndConditionsNotAccepted = 'TermsAndConditionsNotAccepted',
}

export interface AssignSubjectToLeadRequest {
  /** @format int32 */
  partnerId?: number | null;
  partnerAccount?: string | null;
  /** @format int32 */
  dealerId?: number | null;
}

export interface BatchCreateProjectAdditionRequest {
  /** @format int32 */
  projectId: number;
  projectAddition: BatchProjectAdditionRequest[];
}

export interface BatchCreateProjectDeductionRequest {
  /** @format int32 */
  projectId: number;
  projectDeduction: BatchProjectDeductionRequest[];
}

export interface BatchProjectAdditionRequest {
  name: string;
  /** @format double */
  netPrice: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  numberOfWorkHours: number;
  /** @format double */
  hourPrice?: number | null;
  isDeductible: boolean;
}

export interface BatchProjectDeductionRequest {
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface BidDto {
  /** @format int32 */
  id: number;
  type: ProjectType;
  description: string;
  /** @format double */
  totalNetPriceWithoutDeduction: number;
  productCategories: BidProductCategoryDto[];
}

export interface BidPriceDto {
  /** @format int32 */
  id: number;
  name: string;
  /** @format double */
  netPrice: number;
  margin: MarginDto;
  /** @format double */
  totalNetPriceWithoutDeduction: number;
  /** @format double */
  totalNetPrice: number;
  deductions: DeductionDto[];
  additions: AdditionPriceDto[];
}

export interface BidPriceRowDto {
  /** @format int32 */
  projectId: number;
  projectName: string;
  deductions: DeductionDto[];
  /** @format double */
  totalMaterialAndInstallationNetPrice: number;
  /** @format double */
  totalNetPriceWithoutDeduction: number;
  /** @format double */
  totalNetPrice: number;
  /** @format double */
  totalTax: number;
  /** @format double */
  totalGrossPriceWithoutDeduction: number;
  /** @format double */
  totalGrossPrice: number;
}

export interface BidProductCategoryDto {
  /** @format int32 */
  categoryId?: number | null;
  categoryName: string;
  isVisibleInOffer: boolean;
}

export interface BidRowDto {
  /** @format int32 */
  id: number;
  type: ProjectType;
  name: string;
  description: string;
  products: ProjectProductRowDto[];
}

export interface CarChargingProjectDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  leadId: number;
  name: string;
  /** @format int32 */
  numberOfSockets: number;
  /** @format double */
  distanceBetweenChargers: number;
  /** @format double */
  distanceFromCentralToFirstCharger: number;
  /** @format int32 */
  subscriptionId: number;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  carChargerSocketPowerId?: number | null;
  /** @format int32 */
  carChargerSocketsPerChargerId?: number | null;
  /** @format int32 */
  carChargerPlacementId?: number | null;
  /** @format int32 */
  carChargerMountingId?: number | null;
  /** @format int32 */
  routerSwitchTypeId?: number | null;
  /** @format int32 */
  routerSwitchPortsId?: number | null;
  comment?: string | null;
}

export interface CarChargingProjectSettingsDto {
  carChargerSocketPower: ProductAttributeRowDto;
  carChargerSocketsPerCharger: ProductAttributeRowDto;
  carChargerPlacement: ProductAttributeRowDto;
  carChargerMounting: ProductAttributeRowDto;
  routerSwitchType: ProductAttributeRowDto;
  routerSwitchPorts: ProductAttributeRowDto;
}

export interface ConnectedClientDto {
  /** @format uuid */
  clientId?: string;
  isConnected?: boolean;
  userName?: string;
}

export interface ContactDataDto {
  dealer?: DealerContactDataDto;
  supportWeb?: string | null;
}

export interface CreateAddToBasketUrlRequest {
  excludedProjectProductIds: number[];
}

export interface CreateCarChargingProjectRequest {
  /** @format int32 */
  leadId: number;
  /** @format int32 */
  numberOfSockets: number;
  /** @format double */
  distanceBetweenChargers: number;
  /** @format double */
  distanceFromCentralToFirstCharger: number;
  /** @format int32 */
  subscriptionId: number;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  carChargerSocketPowerId?: number | null;
  /** @format int32 */
  carChargerSocketsPerChargerId?: number | null;
  /** @format int32 */
  carChargerPlacementId?: number | null;
  /** @format int32 */
  carChargerMountingId?: number | null;
  /** @format int32 */
  routerSwitchTypeId?: number | null;
  /** @format int32 */
  routerSwitchPortsId?: number | null;
  comment?: string | null;
}

export interface CreateDealerRequest {
  name?: string | null;
  email: string;
  phoneNumber?: string | null;
  street: string;
  zipCode: string;
  city: string;
}

export interface CreateDealerUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  isAdmin: boolean;
  dealerIds: number[];
}

export interface CreateDeductionTemplateRequest {
  /** @format int32 */
  partnerId: number;
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface CreateEmptyProjectRequest {
  /** @format int32 */
  leadId: number;
  name: string;
  comment?: string | null;
}

export interface CreateEnergyStorageProjectRequest {
  /** @format int32 */
  leadId: number;
  name: string;
  comment?: string | null;
}

export interface CreateLeadRequest {
  customerType: LeadCustomerType;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  street: string;
  city: string;
  zipCode: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  propertyDesignation?: string | null;
  organizationName?: string | null;
  organizationNumber?: string | null;
  /** @format int32 */
  assignedPartnerId?: number | null;
  /** @format int32 */
  assignedDealerId?: number | null;
}

export interface CreateOperationUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
}

export interface CreatePartnerRequest {
  /** @format int32 */
  dealerId: number;
  name: string;
  organizationNumber: string;
  accountId: string;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  street: string;
  zipCode: string;
  city: string;
}

export interface CreatePartnerUserRequest {
  /** @format int32 */
  partnerId: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  isAdmin: boolean;
}

export interface CreateProductPackageRequest {
  /** @format int32 */
  categoryId: number;
  name: string;
  isActive: boolean;
  /** @format int32 */
  warranty?: number | null;
  canBeExcludedFromBasket: boolean;
  productPackageItems: ProductPackageItemRequest[];
  attributeValues: ProductAttributeRequest[];
}

export interface CreateProductRequest {
  /** @format int32 */
  categoryId: number;
  name: string;
  isActive: boolean;
  /** @format int32 */
  warranty?: number | null;
  canBeExcludedFromBasket: boolean;
  eNumber: string;
  attributeValues: ProductAttributeRequest[];
}

export interface CreateProjectAdditionRequest {
  /** @format int32 */
  projectId: number;
  name: string;
  /** @format double */
  netPrice: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  numberOfWorkHours: number;
  /** @format double */
  hourPrice?: number | null;
  isDeductible: boolean;
}

export interface CreateProjectAdditionTemplateRequest {
  /** @format int32 */
  partnerId: number;
  name: string;
  /** @format double */
  netPrice: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  numberOfWorkHours: number;
  isDeductible: boolean;
}

export interface CreateProjectDeductionRequest {
  /** @format int32 */
  projectId: number;
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface CreateProjectProductRequest {
  /** @format int32 */
  projectId: number;
  /** @format int32 */
  productId?: number | null;
  customProduct?: CustomProjectProductRequest;
  /** @format double */
  quantity: number;
}

export interface CreateRoofPatchRequest {
  /** @format int32 */
  solarPanelOrientationId?: number | null;
  /** @format int32 */
  angle?: number;
  /** @format int32 */
  direction?: number;
  is90Degrees?: boolean;
  shape?: RoofPatchShape;
  /** @format int32 */
  numberOfRailsId?: number;
  /** @format int32 */
  railOrientationId?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  numberOfRows?: number;
  /** @format int32 */
  numberOfColumns?: number;
  solarPanelGrid?: SolarPanelGridRequest[];
}

export interface CreateRoofRequest {
  /** @format int32 */
  solarPanelManufacturerId?: number | null;
  /** @format int32 */
  solarPanelWidthId?: number | null;
  /** @format int32 */
  solarPanelHeightId?: number | null;
  /** @format int32 */
  roofMaterialId?: number | null;
  /** @format int32 */
  mountingMaterialManufacturerId?: number | null;
  /** @format int32 */
  roofTypeId?: number | null;
  /** @format int32 */
  ridgeHeight?: number;
  snowProtection?: boolean;
  /** @format int32 */
  solarPanelAttachmentId?: number | null;
  /** @format int32 */
  tinOrMetalThicknessId?: number | null;
  /** @format int32 */
  lathDimensionId?: number | null;
  /** @format int32 */
  tileWidth?: number | null;
  /** @format int32 */
  tileHeight?: number | null;
  /** @format int32 */
  distanceBetweenFolds?: number | null;
  /** @format int32 */
  distanceBetweenTopsTinOrMetal?: number | null;
  name?: string;
  customSolarPanel?: CustomSolarPanelRequest;
  patches?: CreateRoofPatchRequest[];
}

export interface CreateSolarEnergyProjectRequest {
  /** @format int32 */
  leadId: number;
  comment?: string | null;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  terrainTypeId: number;
  /** @format int32 */
  panelsResolverVersion: number;
  /** @format double */
  snowLoad: number;
  /** @format int32 */
  windLoad: number;
  roofs: CreateRoofRequest[];
}

export interface CustomProjectProductRequest {
  name: string;
  category: string;
  eNumber: string;
  /** @format int32 */
  warranty?: number | null;
  unit: string;
}

export interface CustomSolarPanelDto {
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  /** @format double */
  weight: number;
  /** @format int32 */
  outputPower: number;
}

export interface CustomSolarPanelRequest {
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  /** @format double */
  weight?: number;
  /** @format int32 */
  outputPower?: number;
}

export interface DealerContactDataDto {
  name?: string;
  email?: string | null;
  phone?: string | null;
  contactPeople?: DealerContactPersonDto[];
}

export interface DealerContactPersonDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string | null;
}

export interface DealerDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  email: string;
  phoneNumber?: string | null;
  address: AddressDto;
  isDeletable: boolean;
}

export interface DealerRowDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  address: AddressDto;
}

export interface DealerUserDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  type: UserType;
  /** @format uri */
  logotypeUri?: string | null;
  isAdmin: boolean;
  isLastAdmin: boolean;
  dealers: DealerRowDto[];
}

export interface DealerUserRowDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  type: UserType;
  isAdmin: boolean;
  isLastAdmin: boolean;
}

export interface DeductionDto {
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  /** @format double */
  netDeduction: number;
  /** @format double */
  grossDeduction: number;
  scope: DeductionScope;
}

export enum DeductionScope {
  MaterialsAndInstallation = 'MaterialsAndInstallation',
  TotalProjectCost = 'TotalProjectCost',
}

export interface DeductionTemplateDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  partnerId: number;
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export enum DeductionType {
  Percentage = 'Percentage',
  Sum = 'Sum',
}

export interface EmptyProjectDto {
  /** @format int32 */
  id: number;
  name: string;
  comment?: string | null;
}

export interface EnergyStorageProjectDto {
  /** @format int32 */
  id: number;
  name: string;
  comment?: string | null;
}

export interface FilteredProductDto {
  /** @format int32 */
  id: number;
  name: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface GetOfferBidPriceOverview {
  /** @format int32 */
  id: number;
  /** @format double */
  margin?: number | null;
}

export enum HeatingType {
  None = 'None',
  District = 'District',
  HeatPump = 'HeatPump',
  Electric = 'Electric',
}

export type InboxAlertDto = UtilRequiredKeys<AlertDto, 'scope' | 'alertType'> & {
  /** @format int32 */
  leadId: number;
  /** @format int32 */
  projectId?: number | null;
};

export interface JsonWebToken {
  accessToken: string;
  /** @format int64 */
  accessTokenExpirationTime: number;
  refreshToken?: string | null;
  /** @format int64 */
  refreshTokenExpirationTime?: number | null;
}

export interface LeadAddressDto {
  street: string;
  zipCode: string;
  city: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  indicatedLatitude?: number | null;
  /** @format double */
  indicatedLongitude?: number | null;
  snowAndWindLoadManuallySet: boolean;
  /** @format double */
  snowLoad?: number | null;
  /** @format int32 */
  windLoad?: number | null;
}

export interface LeadAssignedDealerDto {
  /** @format int32 */
  id?: number;
  name?: string;
}

export interface LeadAssignedPartnerDto {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
  accountId?: string | null;
}

export interface LeadContactPersonDto {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

export enum LeadCustomerType {
  None = 'None',
  HousingCooperative = 'HousingCooperative',
  Business = 'Business',
  Private = 'Private',
}

export interface LeadDto {
  /** @format int32 */
  id: number;
  status: LeadOfferStatus;
  customerType: LeadCustomerType;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  propertyDesignation?: string | null;
  organizationName?: string | null;
  organizationNumber?: string | null;
  address: LeadAddressDto;
  contactPerson: LeadContactPersonDto;
  propertyInfo: LeadPropertyInfo;
  assignedPartner?: LeadAssignedPartnerDto;
  assignedDealer?: LeadAssignedDealerDto;
  /** @format int32 */
  offerId?: number | null;
  /** @format date-time */
  createdDate: string;
  isDeletable: boolean;
}

export enum LeadOfferStatus {
  Created = 'Created',
  Projecting = 'Projecting',
  Projected = 'Projected',
  Accepted = 'Accepted',
  Sent = 'Sent',
  Lost = 'Lost',
  Expired = 'Expired',
}

export interface LeadPropertyInfo {
  propertyType: PropertyType;
  heatingType: HeatingType;
  /** @format int32 */
  mainFuse: number;
  /** @format double */
  availablePower: number;
  /** @format double */
  annualEnergyConsumption: number;
}

export interface LeadRowDto {
  /** @format int32 */
  id: number;
  status: LeadOfferStatus;
  customerType: LeadCustomerType;
  firstName?: string | null;
  lastName?: string | null;
  address: LeadAddressDto;
  assignedPartner?: LeadAssignedPartnerDto;
  assignedDealer?: LeadAssignedDealerDto;
  /** @format int32 */
  offerId?: number | null;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  isCloseToExpire: boolean;
  /** @format date-time */
  expiresOn?: string | null;
}

export interface ManageableProductAttributeRowDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: ProductAttributeValueRowDto[];
  canAddNewValues: boolean;
  unit?: string | null;
}

export interface ManageableProductCategoryRowDto {
  /** @format int32 */
  id: number;
  name: string;
  canAddPackages: boolean;
  attributes: ManageableProductAttributeRowDto[];
}

export interface ManageableProductDto {
  /** @format int32 */
  id: number;
  name: string;
  categoryName: string;
  /** @format int32 */
  warranty?: number | null;
  isActive: boolean;
  eNumber: string;
  canBeExcludedFromBasket: boolean;
  attributeIdToAttributeValueIdMap: Record<string, number>;
}

export interface ManageableProductPackageDto {
  /** @format int32 */
  id: number;
  name: string;
  categoryName: string;
  /** @format int32 */
  warranty?: number | null;
  isActive: boolean;
  canBeExcludedFromBasket: boolean;
  packageItems: ManageableProductPackageItemDto[];
  attributeIdToAttributeValueIdMap: Record<string, number>;
}

export interface ManageableProductPackageItemDto {
  /** @format int32 */
  id: number;
  name: string;
  /** @format int32 */
  quantity: number;
  eNumber: string;
}

export interface ManageableProductRowDto {
  /** @format int32 */
  id: number;
  name: string;
  eNumber?: string | null;
  manufacturerName: string;
  categoryName: string;
  isActive: boolean;
  isPackage: boolean;
}

export interface MarginDto {
  /** @format double */
  value: number;
  /** @format double */
  calculatedValue: number;
}

export interface MaxDistanceBetweenTopsTinOrMetalDto {
  /** @format int32 */
  solarPanelAttachmentId: number;
  /** @format int32 */
  value: number;
}

export interface OfferAgreementAttachmentRowDto {
  name?: string;
  extension?: string;
  /** @format uri */
  attachmentUri?: string;
}

export interface OfferAgreementBasketItems {
  NumCli?: string;
  FlagRAZ?: string;
  FlagLIV?: string;
  LstRefQte?: string;
  FlagGroupingID?: string;
  CampaignName?: string;
}

export interface OfferAgreementBidPriceDto {
  /** @format int32 */
  id: number;
  name: string;
  /** @format double */
  totalNetPrice: number;
  /** @format double */
  totalGrosPrice: number;
  deductions: OfferAgreementDeductionDto[];
}

export interface OfferAgreementCarChargingBidDto {
  /** @format int32 */
  id: number;
  name: string;
  description: string;
  products: OfferAgreementProjectProductRowDto[];
  /** @format int32 */
  subscriptionId: number;
  subscriptionName: string;
  subscriptionDescription?: SubscriptionDescriptionDto;
}

export interface OfferAgreementContactPersonDto {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export interface OfferAgreementDeductionDto {
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  /** @format double */
  calculatedDeduction: number;
  scope: DeductionScope;
}

export interface OfferAgreementDetailsDto {
  /** @format date-time */
  validFromDate?: string | null;
  /** @format date-time */
  validToDate?: string | null;
  introText: string;
  payoff: OfferPayoffDto;
  property: OfferAgreementPropertyDto;
  contactPerson: OfferAgreementContactPersonDto;
  carChargingBids: OfferAgreementCarChargingBidDto[];
  solarEnergyBids: OfferAgreementSolarEnergyBidDto[];
  emptyBids: OfferAgreementEmptyBidDto[];
  energyStorageBids: OfferAgreementEnergyStorageBidDto[];
  attachments: OfferAgreementAttachmentRowDto[];
  price: OfferAgreementPriceDto;
}

export interface OfferAgreementDto {
  /** @format int32 */
  id: number;
  hashedId: string;
  /** @format int32 */
  leadId?: number | null;
  status: LeadOfferStatus;
  details?: OfferAgreementDetailsDto;
  partner: OfferAgreementPartnerDto;
}

export interface OfferAgreementEmptyBidDto {
  /** @format int32 */
  id: number;
  name: string;
  description: string;
  products: OfferAgreementProjectProductRowDto[];
}

export interface OfferAgreementEnergyStorageBidDto {
  /** @format int32 */
  id: number;
  name: string;
  description: string;
  products: OfferAgreementProjectProductRowDto[];
}

export interface OfferAgreementPartnerDto {
  name: string;
  organizationNumber: string;
  /** @format uri */
  logotypeUri?: string | null;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  termsAndConditions?: string | null;
  offerSender?: OfferAgreementSenderDto;
}

export interface OfferAgreementPriceDto {
  /** @format double */
  totalNetPrice: number;
  /** @format double */
  totalTax: number;
  /** @format double */
  totalGrossPrice: number;
  /** @format double */
  totalGrossPriceRounding: number;
  /** @format double */
  totalGrossPriceIncludingRounding: number;
  bidPrices: OfferAgreementBidPriceDto[];
}

export interface OfferAgreementProjectProductRowDto {
  name: string;
  categoryName: string;
  /** @format int32 */
  productWarranty?: number | null;
  productImageUrl?: string | null;
  /** @format double */
  quantity: number;
  unit: string;
  origin: ProjectProductOrigin;
  manufacturerName?: string | null;
  packageItems?: ProductPackageItemDto[] | null;
}

export interface OfferAgreementPropertyDto {
  customerType: LeadCustomerType;
  address: LeadAddressDto;
}

export interface OfferAgreementSenderDto {
  /** @format int32 */
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  /** @format uri */
  logotypeUri?: string | null;
  phoneNumber?: string | null;
}

export interface OfferAgreementSolarEnergyBidDto {
  /** @format int32 */
  id: number;
  name: string;
  description: string;
  products: OfferAgreementProjectProductRowDto[];
  /** @format int32 */
  panelsResolverVersion: number;
  roofs: OfferRoofDto[];
  solarEnergyProduction?: OfferAgreementSolarEnergyProductionDto;
}

export interface OfferAgreementSolarEnergyProductionDto {
  /** @format double */
  peakPower?: number | null;
  /** @format double */
  yearlyProduction?: number | null;
  /** @format double */
  yearlyProductionInkWh?: number | null;
  monthlyProductions?: SolarEnergyProductionMonthlyDto[] | null;
  /** @format double */
  yearlyProductionInWh?: number | null;
}

export interface OfferAttachmentDto {
  /** @format int32 */
  id?: number;
  name?: string;
  fileName?: string;
  /** @format uri */
  attachmentUri?: string;
}

export interface OfferAttachmentRowDto {
  /** @format int32 */
  id?: number;
  name?: string;
  extension?: string;
}

export interface OfferBidBasketItemDto {
  /** @format int32 */
  projectProductId: number;
  /** @format int32 */
  productId?: number | null;
  productName: string;
  productCategoryName: string;
  /** @format double */
  productNetPrice: number;
  /** @format double */
  quantity: number;
  unit: string;
  canBeExcludedFromBasket: boolean;
}

export interface OfferBidBasketItemsDto {
  projectName: string;
  projectType: ProjectType;
  projectProducts: OfferBidBasketItemDto[];
}

export interface OfferDto {
  /** @format int32 */
  id: number;
  hashedId: string;
  status: LeadOfferStatus;
  /** @format date-time */
  validFromDate?: string | null;
  /** @format date-time */
  validToDate?: string | null;
  /** @format int32 */
  numberOfDaysBeforeExpiry: number;
  introText?: string | null;
  bids: BidRowDto[];
  price: OfferPriceDto;
  sender?: OfferSenderDto;
  payoff?: OfferPayoffDto;
}

export interface OfferPayoffDto {
  /** @format double */
  ownToSoldEnergyFactor: number;
  /** @format double */
  purchasePrice: number;
  /** @format double */
  sellingPrice: number;
}

export interface OfferPriceDto {
  /** @format int32 */
  id: number;
  /** @format double */
  totalNetPrice: number;
  /** @format double */
  totalTax: number;
  /** @format double */
  totalGrossPrice: number;
  /** @format double */
  totalGrossPriceRounding: number;
  /** @format double */
  totalGrossPriceIncludingRounding: number;
  bidPrices: BidPriceRowDto[];
}

export interface OfferPriceOverviewDto {
  /** @format double */
  totalNetPrice: number;
  /** @format double */
  totalTax: number;
  /** @format double */
  totalGrossPrice: number;
  /** @format double */
  totalGrossPriceRounding: number;
  /** @format double */
  defaultTotalGrossPriceRounding: number;
  /** @format double */
  totalGrossPriceIncludingRounding: number;
  bids: BidPriceDto[];
}

export interface OfferRoofDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  solarPanelManufacturerId?: number | null;
  solarPanelManufacturerName?: string | null;
  /** @format int32 */
  solarPanelWidthId?: number | null;
  /** @format int32 */
  solarPanelWidth?: number | null;
  /** @format int32 */
  solarPanelHeightId?: number | null;
  /** @format int32 */
  solarPanelHeight?: number | null;
  /** @format int32 */
  roofMaterialId?: number | null;
  roofMaterialName?: string | null;
  /** @format int32 */
  mountingMaterialManufacturerId?: number | null;
  /** @format int32 */
  roofTypeId?: number | null;
  name: string;
  customSolar?: CustomSolarPanelDto;
  solarEnergyProduction?: OfferAgreementSolarEnergyProductionDto;
  patches: OfferRoofPatchDto[];
}

export interface OfferRoofPatchDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  solarPanelOrientationId?: number | null;
  solarPanelOrientation?: Orientation;
  /** @format int32 */
  angle: number;
  /** @format int32 */
  direction: number;
  shape: RoofPatchShape;
  solarPanelGrid: SolarPanelGridDto[];
}

export interface OfferSenderDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
}

export enum Orientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export interface PartnerDealerDto {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface PartnerDto {
  /** @format int32 */
  id: number;
  name: string;
  organizationNumber: string;
  accountId: string;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  address: AddressDto;
  /** @format uri */
  logotypeUri?: string | null;
  termsAndConditions?: string | null;
  offerIntroTextTemplate?: string | null;
  carChargingProjectOfferTextTemplate?: string | null;
  solarEnergyProjectOfferTextTemplate?: string | null;
  emptyProjectOfferTextTemplate?: string | null;
  energyStorageProjectOfferTextTemplate?: string | null;
  /** @format double */
  netCostPerHour: number;
  /** @format double */
  defaultProjectMargin: number;
  dealer?: PartnerDealerDto;
}

export interface PartnerRowDto {
  /** @format int32 */
  id: number;
  name: string;
  organizationNumber: string;
  zipCode: string;
  city: string;
  accountId: string;
  /** @format int32 */
  dealerId?: number | null;
}

export type PartnerSettingsAlertDto = UtilRequiredKeys<AlertDto, 'scope' | 'alertType'> & {
  /** @format int32 */
  partnerId: number;
};

export interface PartnerSettingsDto {
  /** @format double */
  netCostPerHour: number;
}

export interface PartnerUserDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  type: UserType;
  partner: PartnerRowDto;
  /** @format uri */
  logotypeUri?: string | null;
  isAdmin: boolean;
  isLastAdmin: boolean;
  termsAndConditions: PartnerUserTermsAndConditionsDto;
}

export interface PartnerUserRowDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  type: UserType;
  isAdmin: boolean;
  isLastAdmin: boolean;
}

export interface PartnerUserTermsAndConditionsDto {
  /** @format date-time */
  acceptedAtUtc?: string | null;
  isAccepted: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum ProductAttributeDataType {
  None = 'None',
  Alphanumeric = 'Alphanumeric',
  Integer = 'Integer',
  Decimal = 'Decimal',
}

export enum ProductAttributeFilterType {
  None = 'None',
  List = 'List',
  Text = 'Text',
  Number = 'Number',
}

export interface ProductAttributeRequest {
  /** @format int32 */
  attributeId: number;
  /** @format int32 */
  attributeValueId?: number | null;
  value?: string | null;
}

export interface ProductAttributeRowDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: ProductAttributeValueRowDto[];
}

export enum ProductAttributeType {
  Unspecified = 'Unspecified',
  CarChargerConnection = 'CarChargerConnection',
  CarChargerSocketsPerCharger = 'CarChargerSocketsPerCharger',
  CarChargerPlacement = 'CarChargerPlacement',
  CarChargerMounting = 'CarChargerMounting',
  CarChargerSocketPower = 'CarChargerSocketPower',
  SolarPanelManufacturer = 'SolarPanelManufacturer',
  SolarPanelOrientation = 'SolarPanelOrientation',
  SolarPanelWidth = 'SolarPanelWidth',
  SolarPanelHeight = 'SolarPanelHeight',
  RoofMaterial = 'RoofMaterial',
  MountingMaterialManufacturer = 'MountingMaterialManufacturer',
  SolarPowerOutput = 'SolarPowerOutput',
  RouterSwitchType = 'RouterSwitchType',
  RouterSwitchPorts = 'RouterSwitchPorts',
  MaxPanelsPerOptimizer = 'MaxPanelsPerOptimizer',
  InverterMaxEffect = 'InverterMaxEffect',
  DistributionBoxSockets = 'DistributionBoxSockets',
  Weight = 'Weight',
  RoofMaterialGroup = 'RoofMaterialGroup',
  SolarPanelAttachment = 'SolarPanelAttachment',
  RoofType = 'RoofType',
  TinOrMetalThickness = 'TinOrMetalThickness',
  LathDimension = 'LathDimension',
  TerrainType = 'TerrainType',
  NumberOfRails = 'NumberOfRails',
  RailOrientation = 'RailOrientation',
  Strings = 'Strings',
  SnowZone = 'SnowZone',
  ClampType = 'ClampType',
  ScrewType = 'ScrewType',
  Length = 'Length',
  CarChargingManufacturer = 'CarChargingManufacturer',
  SolarManufacturer = 'SolarManufacturer',
  WindZone = 'WindZone',
  OuterSheathColor = 'OuterSheathColor',
  ConnectorType = 'ConnectorType',
  Dimension = 'Dimension',
}

export interface ProductAttributeValueRowDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
}

export interface ProductCategoryAttributeRowDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: ProductCategoryAttributeValueRowDto[];
}

export interface ProductCategoryAttributeValueRowDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  /** @format uri */
  imageUri?: string | null;
  isActive: boolean;
}

export interface ProductCategoryRowDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductCategoryType;
  groupType: ProductGroupType;
  products: ProductDto[];
  productsAttributes: ProductCategoryAttributeRowDto[];
  unit: string;
}

export enum ProductCategoryType {
  Unspecified = 'Unspecified',
  CarCharger = 'CarCharger',
  CarChargerMounting = 'CarChargerMounting',
  SolarPanel = 'SolarPanel',
  MountingMaterial = 'MountingMaterial',
  Inverter = 'Inverter',
  LoadBalancer = 'LoadBalancer',
  Foundation = 'Foundation',
  EnergyMeter = 'EnergyMeter',
  PersonalProtectionCircuitBreaker = 'PersonalProtectionCircuitBreaker',
  License = 'License',
  RouterAndSwitch = 'RouterAndSwitch',
  Optimizer = 'Optimizer',
  DistributionBox = 'DistributionBox',
  SurgeProtector = 'SurgeProtector',
  Cable = 'Cable',
  Screw = 'Screw',
  RoofBracket = 'RoofBracket',
  Clamp = 'Clamp',
  EndCap = 'EndCap',
  Rail = 'Rail',
  Coupling = 'Coupling',
  SafetyLock = 'SafetyLock',
  NetworkSolar = 'NetworkSolar',
  NetworkCarCharging = 'NetworkCarCharging',
  EnergyStorage = 'EnergyStorage',
  SmartEnergyMeter = 'SmartEnergyMeter',
  ACMaterial = 'ACMaterial',
  Contact = 'Contact',
  KitMarking = 'KitMarking',
  CableClamp = 'CableClamp',
  CableTies = 'CableTies',
  FlexTubes = 'FlexTubes',
}

export interface ProductDto {
  /** @format int32 */
  id: number;
  name: string;
  imageUrl?: string | null;
  /** @format int32 */
  warranty?: number | null;
  manufacturerName?: string | null;
  isPackage: boolean;
  productAttributes: ProductAttributeRowDto[];
}

export interface ProductExistenceDto {
  exists: boolean;
  /** @format int32 */
  productId?: number | null;
  imageUrl?: string | null;
}

export enum ProductGroupType {
  CarCharging = 'CarCharging',
  SolarEnergy = 'SolarEnergy',
}

export interface ProductPackageItemDto {
  name: string;
  categoryName: string;
  imageUrl?: string | null;
  /** @format int32 */
  quantity: number;
  unit: string;
  /** @format double */
  price?: number | null;
  /** @format int32 */
  warranty?: number | null;
}

export interface ProductPackageItemRequest {
  /** @format int32 */
  productId: number;
  /** @format int32 */
  quantity: number;
}

export interface ProjectAdditionDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  projectId: number;
  name: string;
  /** @format double */
  netPrice: number;
  /** @format double */
  quantity: number;
  /** @format double */
  numberOfWorkHours: number;
  /** @format double */
  hourPrice: number;
  isDeductible: boolean;
}

export interface ProjectAdditionTemplateDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  partnerId: number;
  name: string;
  /** @format double */
  netPrice: number;
  /** @format double */
  quantity: number;
  /** @format double */
  numberOfWorkHours: number;
  isDeductible: boolean;
}

export interface ProjectDeductionDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  projectId: number;
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface ProjectDeductionRowDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  projectId: number;
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  /** @format double */
  netDeduction: number;
  /** @format double */
  grossDeduction: number;
  scope: DeductionScope;
}

export interface ProjectDto {
  /** @format int32 */
  id: number;
  type: ProjectType;
  name: string;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  additions: ProjectAdditionDto[];
  deductions: ProjectDeductionRowDto[];
  projectProducts: ProjectProductRowDto[];
  /** @format int32 */
  subscriptionId?: number | null;
  subscriptionName?: string | null;
  isCompleted: boolean;
  isSavingInProgress: boolean;
  solarEnergyProjectDetails?: SolarEnergyProjectRowDto;
}

export interface ProjectProductDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  productId?: number | null;
  productName: string;
  productENumber?: string | null;
  productCategoryName: string;
  /** @format double */
  productNetPrice: number;
  /** @format int32 */
  productNumberOfWorkHours: number;
  /** @format int32 */
  productWarranty?: number | null;
  productImageUrl?: string | null;
  /** @format double */
  quantity: number;
  unit: string;
  origin: ProjectProductOrigin;
  isExcluded: boolean;
  filters: ProductAttributeValueRowDto[];
}

export interface ProjectProductGroup {
  /** @format int32 */
  groupId: number;
  groupName: string;
  /** @format uri */
  pdfReportUri?: string | null;
}

export enum ProjectProductOrigin {
  User = 'User',
  Template = 'Template',
  Custom = 'Custom',
}

export interface ProjectProductRowDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  productId?: number | null;
  productName: string;
  productCategoryName: string;
  /** @format double */
  productNetPrice: number;
  /** @format int32 */
  productNumberOfWorkHours: number;
  /** @format int32 */
  productWarranty?: number | null;
  productImageUrl?: string | null;
  /** @format double */
  quantity: number;
  unit: string;
  origin: ProjectProductOrigin;
  isExcluded: boolean;
  isPackage: boolean;
  isUnavailable: boolean;
  manufacturerName?: string | null;
  group?: ProjectProductGroup;
}

export interface ProjectProductTemplateDto {
  /** @format int32 */
  id: number;
  name: string;
}

export enum ProjectType {
  None = 'None',
  CarCharging = 'CarCharging',
  SolarEnergy = 'SolarEnergy',
  Empty = 'Empty',
  EnergyStorage = 'EnergyStorage',
}

export enum PropertyType {
  None = 'None',
  House = 'House',
  ApartmentBuilding = 'ApartmentBuilding',
  OfficePremises = 'OfficePremises',
  Warehouse = 'Warehouse',
  Hotel = 'Hotel',
}

export interface RailImageDto {
  /** @format int32 */
  solarPanelOrientationId: number;
  /** @format int32 */
  numberOfRailsId: number;
  /** @format uri */
  imageUri: string;
}

export interface RailOrientationAttributeDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: RailOrientationAttributeValueDto[];
}

export interface RailOrientationAttributeValueDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
  orientation: Orientation;
  imageUris: RailImageDto[];
}

export interface RefreshRequest {
  refreshToken?: string;
}

export interface ResetPasswordRequest {
  email: string;
  token: string;
  password: string;
}

export interface RoofDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  solarPanelManufacturerId?: number | null;
  /** @format int32 */
  solarPanelWidthId?: number | null;
  /** @format int32 */
  solarPanelHeightId?: number | null;
  /** @format int32 */
  roofMaterialId?: number | null;
  /** @format int32 */
  mountingMaterialManufacturerId?: number | null;
  /** @format int32 */
  roofTypeId?: number | null;
  /** @format int32 */
  solarPanelAttachmentId?: number | null;
  /** @format int32 */
  tinOrMetalThicknessId?: number | null;
  /** @format int32 */
  lathDimensionId?: number | null;
  name: string;
  /** @format int32 */
  ridgeHeight: number;
  snowProtection: boolean;
  /** @format int32 */
  tileWidth?: number | null;
  /** @format int32 */
  tileHeight?: number | null;
  /** @format int32 */
  distanceBetweenFolds?: number | null;
  /** @format int32 */
  distanceBetweenTopsTinOrMetal?: number | null;
  customSolar?: CustomSolarPanelDto;
  patches: RoofPatchDto[];
}

export interface RoofMaterialAttributeDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: RoofMaterialAttributeValueDto[];
}

export interface RoofMaterialAttributeValueDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
  /** @format int32 */
  roofMaterialGroupId: number;
  /** @format int32 */
  defaultDistanceBetweenFolds?: number | null;
  /** @format int32 */
  minDistanceBetweenFolds?: number | null;
  /** @format int32 */
  maxDistanceBetweenFolds?: number | null;
  /** @format int32 */
  defaultDistanceBetweenTopsTinOrMetal?: number | null;
  maxDistanceBetweenTopsTinOrMetal?: MaxDistanceBetweenTopsTinOrMetalDto[] | null;
  /** @format int32 */
  defaultTileWidth?: number | null;
  /** @format int32 */
  defaultTileHeight?: number | null;
  /** @format int32 */
  maxTileWidth?: number | null;
  /** @format int32 */
  maxTileHeight?: number | null;
  availableTinOrMetalThicknessIds?: number[] | null;
  availableAttachmentIds?: number[] | null;
  availablePanelOrientationIds?: number[] | null;
  availableRailOrientationIds?: number[] | null;
}

export interface RoofMaterialMountingMaterialDto {
  /** @format int32 */
  roofMaterialId: number;
  solarPanelOrientations: RoofMaterialSolarPanelOrientationDto[];
}

export interface RoofMaterialSolarPanelOrientationDto {
  /** @format int32 */
  solarPanelOrientationId: number;
  mountingMaterialManufacturerIds: number[];
}

export interface RoofPatchDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  solarPanelOrientationId?: number | null;
  /** @format int32 */
  angle: number;
  /** @format int32 */
  direction: number;
  is90Degrees: boolean;
  shape: RoofPatchShape;
  /** @format int32 */
  numberOfRailsId: number;
  /** @format int32 */
  railOrientationId: number;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  /** @format int32 */
  numberOfRows: number;
  /** @format int32 */
  numberOfColumns: number;
  solarPanelGrid: SolarPanelGridDto[];
  solarEnergyProduction: SolarEnergyProductionDto;
}

export interface RoofPatchShape {
  v1?: RoofPatchShapeVertex;
  v2?: RoofPatchShapeVertex;
  v3?: RoofPatchShapeVertex;
  v4?: RoofPatchShapeVertex;
  /** @format int32 */
  topWidth?: number;
  /** @format int32 */
  rightHeight?: number;
  /** @format int32 */
  leftHeight?: number;
  /** @format int32 */
  bottomWidth?: number | null;
  /** @format double */
  topWithInMeters?: number;
  /** @format double */
  rightHeightInMeters?: number;
  /** @format double */
  leftHeightInMeters?: number;
  /** @format double */
  bottomWidthInMeters?: number | null;
}

export interface RoofPatchShapeVertex {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface SignInRequest {
  userName: string;
  password: string;
  rememberMe: boolean;
}

export interface SolarEnergyProductionDto {
  /** @format double */
  yearlyProduction: number;
  /** @format double */
  yearlyProductionInkWh: number;
  /** @format double */
  peakPower: number;
}

export interface SolarEnergyProductionForRoofDto {
  /** @format double */
  yearlyProduction: number;
  /** @format double */
  yearlyProductionInkWh: number;
  /** @format double */
  yearlyProductionInkWhHundreds: number;
  /** @format double */
  peekPower: number;
}

export interface SolarEnergyProductionMonthlyDto {
  /** @format int32 */
  month: number;
  /** @format double */
  production: number;
  /** @format double */
  productionInkWh: number;
}

export interface SolarEnergyProjectDto {
  /** @format int32 */
  id: number;
  name: string;
  snowAndWindLoadManuallySet: boolean;
  /** @format double */
  snowLoad?: number | null;
  /** @format int32 */
  windLoad?: number | null;
  roofs: RoofDto[];
  comment?: string | null;
  /** @format int32 */
  leadId: number;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  terrainTypeId: number;
  /** @format int32 */
  panelsResolverVersion: number;
}

export interface SolarEnergyProjectRowDto {
  solarEnergyProductionForRoof: SolarEnergyProductionForRoofDto[];
  /** @format int32 */
  numberOfRoofs: number;
  /** @format double */
  totalYearlyProduction: number;
  /** @format double */
  totalYearlyProductionInkWh: number;
  /** @format double */
  totalYearlyProductionInkWhHundreds: number;
}

export interface SolarEnergyProjectSettingsDto {
  solarPanelManufacturer: SolarPanelManufacturerAttributeDto;
  solarPanelOrientation: SolarPanelOrientationAttributeDto;
  solarPanelWidth: ProductAttributeRowDto;
  solarPanelHeight: ProductAttributeRowDto;
  roofMaterialGroups: ProductAttributeRowDto;
  roofMaterial: RoofMaterialAttributeDto;
  mountingMaterialManufacturer: ProductAttributeRowDto;
  solarPanelAttachments: ProductAttributeRowDto;
  roofType: ProductAttributeRowDto;
  tinOrMetalThickness: ProductAttributeRowDto;
  lathDimension: ProductAttributeRowDto;
  terrainType: TerrainTypeAttributeDto;
  numberOfRails: ProductAttributeRowDto;
  railOrientation: RailOrientationAttributeDto;
  snowLoad: ProductAttributeRowDto;
  windLoad: ProductAttributeRowDto;
  roofMaterialMountingMaterialsMap: RoofMaterialMountingMaterialDto[];
}

export interface SolarPanelGridDto {
  /** @format int32 */
  row: number;
  /** @format int32 */
  col: number;
  isActive: boolean;
  isInShape: boolean;
}

export interface SolarPanelGridRequest {
  /** @format int32 */
  row?: number;
  /** @format int32 */
  col?: number;
  isActive?: boolean;
  isInShape?: boolean;
}

export interface SolarPanelManufacturerAttributeDto {
  /** @format int32 */
  id?: number;
  name?: string;
  type?: ProductAttributeType;
  dataType?: ProductAttributeDataType;
  filterType?: ProductAttributeFilterType;
  values?: SolarPanelManufacturerAttributeValueDto[];
}

export interface SolarPanelManufacturerAttributeValueDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
  availableSolarPanelSizes: SolarPanelSizeDto[];
}

export interface SolarPanelOrientationAttributeDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: SolarPanelOrientationAttributeValueDto[];
}

export interface SolarPanelOrientationAttributeValueDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
  orientation?: Orientation;
}

export interface SolarPanelSizeDto {
  /** @format int32 */
  widthId?: number;
  /** @format int32 */
  heightId?: number;
}

export interface SubscriptionDescriptionDto {
  text?: string | null;
  bullets?: string[] | null;
}

export interface SubscriptionDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  sortOrder: number;
  name: string;
  description?: SubscriptionDescriptionDto;
}

export interface SystemMessageDto {
  title?: string | null;
  message?: string | null;
  isEnabled: boolean;
}

export interface SystemSettingsDto {
  offerIntroDefaultText?: string | null;
  carChargingProjectDefaultOfferText?: string | null;
  solarEnergyProjectDefaultOfferText?: string | null;
  emptyProjectDefaultOfferText?: string | null;
  energyStorageProjectDefaultOfferText?: string | null;
  termsAndConditions: string;
}

export interface TeaserContentDto {
  /** @format int32 */
  id: number;
  heading?: string | null;
  text?: string | null;
  imageUrl?: string | null;
  url: string;
}

export interface TermsAndConditionsDto {
  termsAndConditions: string;
}

export interface TerrainTypeAttributeDto {
  /** @format int32 */
  id: number;
  name: string;
  type: ProductAttributeType;
  dataType: ProductAttributeDataType;
  filterType: ProductAttributeFilterType;
  values: TerrainTypeAttributeValueDto[];
}

export interface TerrainTypeAttributeValueDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  attributeId: number;
  name?: string | null;
  filterType?: ProductAttributeFilterType;
  /** @format uri */
  imageUri?: string | null;
  type: string;
  description: string;
}

export interface UpdateBidPriceRequest {
  /** @format int32 */
  id: number;
  /** @format double */
  margin: number;
}

export interface UpdateBidRequest {
  description: string;
  /** @format double */
  manualTotalVat?: number | null;
  /** @format double */
  manualTotalNetPrice?: number | null;
  categoriesVisibleInOffer: number[];
  customProductCategoriesVisibleInOffer: string[];
}

export interface UpdateCarChargingProjectRequest {
  /** @format int32 */
  numberOfSockets: number;
  /** @format double */
  distanceBetweenChargers: number;
  /** @format double */
  distanceFromCentralToFirstCharger: number;
  /** @format int32 */
  subscriptionId: number;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  carChargerSocketPowerId?: number | null;
  /** @format int32 */
  carChargerSocketsPerChargerId?: number | null;
  /** @format int32 */
  carChargerPlacementId?: number | null;
  /** @format int32 */
  carChargerMountingId?: number | null;
  /** @format int32 */
  routerSwitchTypeId?: number | null;
  /** @format int32 */
  routerSwitchPortsId?: number | null;
  comment?: string | null;
}

export interface UpdateDealerRequest {
  name?: string | null;
  email: string;
  phoneNumber?: string | null;
  street: string;
  zipCode: string;
  city: string;
}

export interface UpdateDealerUserRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  isAdmin: boolean;
  dealerIds?: number[] | null;
}

export interface UpdateDeductionTemplateRequest {
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface UpdateEmptyProjectRequest {
  name: string;
  comment?: string | null;
}

export interface UpdateEnergyStorageProjectRequest {
  name: string;
  comment?: string | null;
}

export interface UpdateLeadContactPersonRequest {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

export interface UpdateLeadPropertyInfoRequest {
  propertyType: PropertyType;
  heatingType: HeatingType;
  /** @format int32 */
  mainFuse: number;
  /** @format double */
  availablePower: number;
  /** @format double */
  annualEnergyConsumption: number;
}

export interface UpdateLeadRequest {
  customerType: LeadCustomerType;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  street: string;
  city: string;
  zipCode: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  indicatedLatitude?: number | null;
  /** @format double */
  indicatedLongitude?: number | null;
  propertyDesignation?: string | null;
  organizationName?: string | null;
  organizationNumber?: string | null;
  contactPerson?: UpdateLeadContactPersonRequest;
  propertyInfo?: UpdateLeadPropertyInfoRequest;
}

export interface UpdateOfferAgreementRequest {
  status: LeadOfferStatus;
}

export interface UpdateOfferPriceOverviewRequest {
  /** @format double */
  priceRounding?: number | null;
  bids: UpdateBidPriceRequest[];
}

export interface UpdateOfferRequest {
  introText?: string | null;
  /** @format int32 */
  offerSenderId?: number | null;
  /** @format int32 */
  numberOfDaysBeforeExpiry: number;
  /** @format int32 */
  ownToSoldEnergyFactor?: number | null;
  /** @format double */
  purchasePrice?: number | null;
  /** @format double */
  sellingPrice?: number | null;
}

export interface UpdateOperationUserRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
}

export interface UpdatePartnerCustomerInformationRequest {
  /** @format int32 */
  dealerId: number;
  accountId: string;
}

export interface UpdatePartnerRequest {
  name: string;
  organizationNumber: string;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  street: string;
  zipCode: string;
  city: string;
  termsAndConditions?: string | null;
  offerIntroTextTemplate?: string | null;
  carChargingProjectOfferTextTemplate?: string | null;
  solarEnergyProjectOfferTextTemplate?: string | null;
  emptyProjectOfferTextTemplate?: string | null;
  energyStorageProjectOfferTextTemplate?: string | null;
  /** @format double */
  netCostPerHour: number;
  /** @format double */
  defaultProjectMargin: number;
}

export interface UpdatePartnerUserRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  isAdmin: boolean;
}

export interface UpdateProductPackageRequest {
  name: string;
  isActive: boolean;
  /** @format int32 */
  warranty?: number | null;
  canBeExcludedFromBasket: boolean;
  packageItems: ProductPackageItemRequest[];
  attributeValues: ProductAttributeRequest[];
}

export interface UpdateProductRequest {
  name: string;
  isActive: boolean;
  /** @format int32 */
  warranty?: number | null;
  canBeExcludedFromBasket: boolean;
  attributeValues: ProductAttributeRequest[];
}

export interface UpdateProjectAdditionRequest {
  name: string;
  /** @format double */
  netPrice: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  numberOfWorkHours: number;
  /** @format double */
  hourPrice?: number | null;
  isDeductible: boolean;
}

export interface UpdateProjectAdditionTemplateRequest {
  name: string;
  /** @format double */
  netPrice: number;
  /** @format int32 */
  quantity: number;
  /** @format int32 */
  numberOfWorkHours: number;
  isDeductible: boolean;
}

export interface UpdateProjectDeductionRequest {
  name: string;
  type: DeductionType;
  /** @format double */
  value: number;
  scope: DeductionScope;
}

export interface UpdateProjectProductRequest {
  /** @format int32 */
  productId?: number | null;
  customProduct?: CustomProjectProductRequest;
  /** @format double */
  quantity: number;
  isExcluded: boolean;
}

export interface UpdateRoofPatchRequest {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  solarPanelOrientationId?: number | null;
  /** @format int32 */
  angle?: number;
  /** @format int32 */
  direction?: number;
  is90Degrees?: boolean;
  /** @format int32 */
  numberOfRailsId?: number;
  /** @format int32 */
  railOrientationId?: number;
  shape?: RoofPatchShape;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  numberOfRows?: number;
  /** @format int32 */
  numberOfColumns?: number;
  solarPanelGrid?: SolarPanelGridRequest[];
}

export interface UpdateRoofRequest {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  solarPanelManufacturerId?: number | null;
  /** @format int32 */
  solarPanelWidthId?: number | null;
  /** @format int32 */
  solarPanelHeightId?: number | null;
  /** @format int32 */
  roofMaterialId?: number | null;
  /** @format int32 */
  mountingMaterialManufacturerId?: number | null;
  /** @format int32 */
  roofTypeId?: number | null;
  name?: string;
  /** @format int32 */
  ridgeHeight?: number;
  snowProtection?: boolean;
  /** @format int32 */
  solarPanelAttachmentId?: number | null;
  /** @format int32 */
  tinOrMetalThicknessId?: number | null;
  /** @format int32 */
  lathDimensionId?: number | null;
  /** @format int32 */
  tileWidth?: number | null;
  /** @format int32 */
  tileHeight?: number | null;
  /** @format int32 */
  distanceBetweenFolds?: number | null;
  /** @format int32 */
  distanceBetweenTopsTinOrMetal?: number | null;
  customSolarPanel?: CustomSolarPanelRequest;
  patches?: UpdateRoofPatchRequest[];
}

export interface UpdateSolarEnergyProjectRequest {
  comment?: string | null;
  /** @format int32 */
  projectProductTemplateId: number;
  /** @format int32 */
  terrainTypeId: number;
  /** @format int32 */
  panelsResolverVersion: number;
  /** @format double */
  snowLoad: number;
  /** @format int32 */
  windLoad: number;
  roofs: UpdateRoofRequest[];
}

export interface UpdateSystemMessageRequest {
  title?: string | null;
  message?: string | null;
}

export interface UpdateSystemSettingsRequest {
  offerIntroDefaultText?: string | null;
  carChargingProjectDefaultOfferText?: string | null;
  solarEnergyProjectDefaultOfferText?: string | null;
  emptyProjectDefaultOfferText?: string | null;
  energyStorageProjectDefaultOfferText?: string | null;
}

export type UserAccountAlertDto = UtilRequiredKeys<AlertDto, 'scope' | 'alertType'> & {
  /** @format int32 */
  userId: number;
};

export interface UserDto {
  /** @format int32 */
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  type: UserType;
  /** @format uri */
  logotypeUri?: string | null;
  isAdmin: boolean;
  isLastAdmin: boolean;
}

export enum UserType {
  None = 'None',
  Operation = 'Operation',
  Partner = 'Partner',
  Dealer = 'Dealer',
}
