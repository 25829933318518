import Button from '@mui/material/Button';
import { FC, ReactNode, useRef, useState } from 'react';

import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronDown } from '@mdi/js';

import { Menu, Stack, SxProps, Typography } from '@mui/material';
export interface DropdownProps {
  children: ReactNode;
  dropdownHeader?: ReactNode;
  iconButtonIconPath?: string;
  buttonText?: string;
  disabled?: boolean;
  isDropdownOpen: boolean;
  toggleIsDropdownOpen: () => void;
  styles?: SxProps;
  /** If the dropdown has more complex content than just list of items. */
  complexMenu?: boolean;
  placeIconAfterText?: boolean;
  /** If the dropdown should open towards the left of the page */
  transformLeft?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  dropdownHeader,
  iconButtonIconPath = mdiChevronDown,
  buttonText,
  disabled,
  isDropdownOpen,
  toggleIsDropdownOpen,
  styles,
  complexMenu,
  placeIconAfterText,
  transformLeft,
}) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const buttonElement = useRef<HTMLButtonElement>(null);

  const onDropdownButtonClick = () => {
    setAnchorElement(buttonElement.current);
    toggleIsDropdownOpen();
  };

  return (
    <>
      <Button
        className={isDropdownOpen ? `Mui-selected` : undefined}
        sx={{
          ...{
            '&.MuiButtonBase-root': { gap: 2 },
            '& .MuiButton-endIcon, & .MuiButton-startIcon': { margin: 0 },
            paddingInlineStart: placeIconAfterText ? 2 : 1.4,
            ...styles,
          },
        }}
        variant="text"
        startIcon={placeIconAfterText ? undefined : <MdiIconWrapper path={iconButtonIconPath} />}
        endIcon={placeIconAfterText ? <MdiIconWrapper path={iconButtonIconPath} /> : undefined}
        onClick={onDropdownButtonClick}
        ref={buttonElement}
        disabled={disabled}
        id={`${buttonText}-button`}
        aria-controls={`${buttonText}-menu`}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen ? `true` : undefined}
      >
        <Typography variant="h4">{buttonText}</Typography>
      </Button>
      <Menu
        data-testid={`${buttonText}-dropdown`}
        id={`${buttonText}-menu`}
        style={{ zIndex: 1200 }}
        open={isDropdownOpen}
        anchorEl={anchorElement}
        onClose={() => {
          setAnchorElement(null);
          toggleIsDropdownOpen();
        }}
        MenuListProps={{
          'aria-labelledby': `${buttonText}-button`,
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: complexMenu ? 340 : `fit-content`,
          },
        }}
        anchorOrigin={{ horizontal: transformLeft ? `right` : `left`, vertical: `bottom` }}
        transformOrigin={{ horizontal: transformLeft ? `right` : `left`, vertical: `top` }}
      >
        <Stack>
          {dropdownHeader}
          {children}
        </Stack>
      </Menu>
    </>
  );
};
