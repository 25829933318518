import { Stack } from '@mui/material';
import { LeadsFilter, LeadsFilterProps } from '@pages/NewLeads/LeadsFilters';
import { AlertCenter } from '@components/AlertCenter/AlertCenter';

type LeadsTitleComponentsProps = LeadsFilterProps;

export const LeadsTitleComponents: React.FC<LeadsTitleComponentsProps> = (props) => (
  <Stack direction="row">
    <LeadsFilter {...props} />
    <AlertCenter />
  </Stack>
);
