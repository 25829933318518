import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { SettingsLeftNavLink } from '@components/LeftNavLink/SettingsLeftNavLink';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Outlet, useLocation } from 'react-router-dom';
import { AlertCenter } from '@components/AlertCenter/AlertCenter';

export const MyAccountPage = () => {
  const location = useLocation();
  const {
    translate,
    translations: {
      myAccount: { listNames },
    },
  } = useTranslations();

  return (
    <ColumnLayout
      title={translate(listNames.myAccount)}
      list={[
        <SettingsLeftNavLink
          key="my-account"
          href="/my-account"
          title={translate(listNames.myAccount)}
          selected={location.pathname === `/my-account`}
        />,
      ]}
      titleComponents={<AlertCenter />}
    >
      <Outlet />
    </ColumnLayout>
  );
};
