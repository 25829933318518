import Drawer from '@mui/material/Drawer';
import Stack from '@mui/system/Stack';
import { mdiClose } from '@mdi/js';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { SelectDealers } from '@components/navbar/SelectDealers';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Route, useRoutesWithTranslations } from '@components/navbar/constants';

import '../../navbar/navbar.css';
import { PageTouchPointWrapper } from '@components/external/TouchPointFeedback/PageTouchPointWrapper';
import { HelpSupportModal } from '@components/HelpSupportModal';
import { MenuItem } from '@components/Layout/Menu/MenuItem';
import { Box } from '@mui/material';
import { PartnerProfile } from '@components/navbar/PartnerProfile';
import { UserProfile } from '@components/navbar/UserProfile';
import Divider from '@mui/material/Divider';

export interface MenuProps {
  isDrawerOpen: boolean;
  toggleIsDrawerOpen: () => void;
}

export const Menu = ({ isDrawerOpen, toggleIsDrawerOpen }: MenuProps) => {
  const theme = useTheme();
  const currentRoute = useLocation().pathname;
  const { baseRoutes, userSectionRoutes } = useRoutesWithTranslations();

  const getOptionsForRoutes = useCallback(
    (routes: Route[], profileItems = false) =>
      routes.map(({ name, path, icon, disabled }) => (
        <MenuItem
          key={path}
          name={name}
          path={path}
          active={currentRoute.includes(path)}
          nameVariant={profileItems ? `h3` : `h2`}
          onActivate={toggleIsDrawerOpen}
          icon={icon}
          disabled={disabled}
        />
      )),
    [currentRoute, toggleIsDrawerOpen],
  );

  return (
    <Drawer open={isDrawerOpen} onClose={toggleIsDrawerOpen} keepMounted>
      <Stack
        className="drawer-content"
        spacing={2}
        justifyContent="space-between"
        height="100%"
        padding={3}
        marginBottom={5}
      >
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="end" alignItems="end">
            <SelectDealers toggleIsDrawerOpen={toggleIsDrawerOpen} />
            <PartnerProfile />
            <IconButton sx={{ marginLeft: `auto` }} onClick={toggleIsDrawerOpen}>
              <MdiIconWrapper path={mdiClose} color={theme.palette.primary.dark} />
            </IconButton>
          </Stack>
          <Box height={48} />
          <Stack spacing={1}>{getOptionsForRoutes(baseRoutes)}</Stack>
        </Stack>
        <Stack className="nav-options" sx={{ marginBlockStart: 3 }} spacing={1}>
          <PageTouchPointWrapper />
          <HelpSupportModal />
          {getOptionsForRoutes(userSectionRoutes, true)}
          <Divider />
          <UserProfile />
        </Stack>
      </Stack>
    </Drawer>
  );
};
