import { useSelector } from 'react-redux';
import { selectIsPartnerUser } from '@redux/reducers/slices/user';
import { useGetPartner } from '@services/api/partners';
import { Avatar, Stack, Typography } from '@mui/material';

export const PartnerProfile = () => {
  const isUserPartner = useSelector(selectIsPartnerUser);
  const { partner } = useGetPartner();

  if (!isUserPartner) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
      {partner?.logotypeUri && (
        <Avatar alt="partner logo image" src={partner?.logotypeUri} sx={{ width: 48, height: 48 }} />
      )}
      <Typography variant="h4">{partner?.name}</Typography>
    </Stack>
  );
};
