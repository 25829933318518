import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslations } from '@services/hooks/translations/useTranslations';

import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';

import { useForgotPassword } from '@services/api/accounts';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { requiredEmail } from '@variables/zod';
import { RexelLogo } from '@components/rexelLogo/RexelLogo';

const forgotPasswordZodObject = {
  email: requiredEmail,
};

const forgotPasswordSchema = z.object(forgotPasswordZodObject);

export type ForgotPasswordValuesType = z.infer<typeof forgotPasswordSchema>;

const initialValues = { email: `` };

export function ForgotPassword() {
  const {
    translations: { forgotPassword, resetPasswordConfirmation },
    translate,
  } = useTranslations();
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm({
    values: initialValues,
    resolver: zodResolver(forgotPasswordSchema),
  });

  const { resetPassword, isResettingPassword } = useForgotPassword(() => {
    navigate(`/forgot-password-confirmation/${getValues().email}`);
  });

  const handleResetPaswordSubmit = useCallback(
    (values: ForgotPasswordValuesType) => {
      resetPassword(values);
    },
    [resetPassword],
  );

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        width: `100%`,
        height: `100%`,
      })}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          position: `absolute`,
          top: 0,
          left: 0,
          marginRight: `auto`,
          marginLeft: 7,
          marginTop: 6,
        }}
      >
        <RexelLogo />
      </Box>
      <Box className="fw fh column col-1-mobile-small col-4-desktop" top="25%" position="absolute">
        <form className="column left-center gap gap-small fw" onSubmit={handleSubmit(handleResetPaswordSubmit)}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Typography variant="h1">{translate(forgotPassword.emailHeader)}</Typography>
              <Typography>{translate(forgotPassword.description)}</Typography>
              <InputWrapper control={control} name="email" label={translate(forgotPassword.email)} />
            </Stack>
            <Button variant="contained" type="submit" disabled={isResettingPassword}>
              {translate(forgotPassword.send)}
            </Button>
            <Link sx={{ textUnderlineOffset: 5 }} href="/login">
              {translate(resetPasswordConfirmation.backToLogin)}
            </Link>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
}
