import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationsConnectionState,
  selectNotificationsConnectionState,
  setNotificationsConnectionState,
} from '@redux/reducers/slices/systemSettings';
import {
  useAuthenticateToNotifications,
  useNotificationsConnection as useNotificationsConnectionApi,
} from '@services/api/notifications/notifications';
import { useCallback, useEffect, useState } from 'react';
import { selectIsLoggedIn } from '@redux/reducers/slices/user';
import { useCommonHandlers } from '@services/hooks/notifications/handlers/commonHandlers';
import { useSystemMessagesHandlers } from '@services/hooks/notifications/handlers/systemMessagesHandlers';
import { useAlertsHandlers } from '@services/hooks/notifications/handlers/alertsHandles';

const useNotificationsAuthentication = () => {
  const dispatch = useDispatch();
  const notificationsState = useSelector(selectNotificationsConnectionState);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { authenticate } = useAuthenticateToNotifications({
    onSuccess: () => dispatch(setNotificationsConnectionState(NotificationsConnectionState.Authenticated)),
    onError: () => dispatch(setNotificationsConnectionState(NotificationsConnectionState.Failed)),
  });

  useEffect(() => {
    if (isLoggedIn) {
      if (notificationsState === NotificationsConnectionState.Initial) {
        dispatch(setNotificationsConnectionState(NotificationsConnectionState.Authenticating));
        authenticate();
      }
    }
  }, [notificationsState, authenticate, dispatch, isLoggedIn]);
};

const useNotificationsConnection = () => {
  const dispatch = useDispatch();
  const notificationsState = useSelector(selectNotificationsConnectionState);
  const [connectionAttempts, setConnectionAttempts] = useState(0);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const resetConnectionAttempts = useCallback(() => {
    setConnectionAttempts(0);
  }, []);

  const commonHandlers = useCommonHandlers({ handleOpen: resetConnectionAttempts });
  const systemMessagesHandlers = useSystemMessagesHandlers();
  const alertsHandlers = useAlertsHandlers();

  const { connect, disconnect } = useNotificationsConnectionApi({
    ...commonHandlers,
    ...systemMessagesHandlers,
    ...alertsHandlers,
  });

  useEffect(() => {
    if (isLoggedIn) {
      if (notificationsState === NotificationsConnectionState.Authenticated) {
        dispatch(setNotificationsConnectionState(NotificationsConnectionState.Connecting));
        setConnectionAttempts((currentAttempts) => currentAttempts + 1);
        connect();
      }
    }
    if (!isLoggedIn) {
      if (notificationsState !== NotificationsConnectionState.Initial) {
        disconnect();
        dispatch(setNotificationsConnectionState(NotificationsConnectionState.Initial));
      }
    }
    if (notificationsState === NotificationsConnectionState.Failed) {
      disconnect();
      setTimeout(() => {
        dispatch(setNotificationsConnectionState(NotificationsConnectionState.Initial));
      }, connectionAttempts * 1000);
    }
  }, [notificationsState, dispatch, connect, disconnect, isLoggedIn, connectionAttempts]);

  useEffect(() => {
    resetConnectionAttempts();
    return () => {
      disconnect();
      dispatch(setNotificationsConnectionState(NotificationsConnectionState.Initial));
      resetConnectionAttempts();
    };
  }, [resetConnectionAttempts, disconnect, dispatch]);
};

export const useNotifications = () => {
  useNotificationsAuthentication();
  useNotificationsConnection();
};
