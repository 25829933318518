import { UserType } from '@generatedTypes/data-contracts';
import { AppState } from '@redux/reducers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetUser } from '@services/api/users';
import { selectIsPartnerUser, selectTermsAndConditionsAcceptedFromToken } from '@redux/reducers/slices/user';
import {
  mdiAccountBoxOutline,
  mdiAccountSupervisorCircleOutline,
  mdiCogOutline,
  mdiInboxOutline,
  mdiStorefrontOutline,
} from '@mdi/js';

const ROUTES = {
  leads: `leads`,
  partners: `partners`,
  users: `users`,
  dealers: `dealers`,
  systemSettings: `systemSettings`,
} as const;

type Routes = (typeof ROUTES)[keyof typeof ROUTES];

const RoutesPerUserType: {
  base: {
    [key in UserType]: Routes[];
  };
  userSection: {
    [key in UserType]: Routes[];
  };
} = {
  base: {
    [UserType.Operation]: [ROUTES.leads, ROUTES.partners, ROUTES.users, ROUTES.dealers],
    [UserType.Partner]: [ROUTES.leads],
    [UserType.Dealer]: [ROUTES.leads, ROUTES.partners, ROUTES.dealers],
    [UserType.None]: [],
  },
  userSection: {
    [UserType.Operation]: [ROUTES.systemSettings],
    [UserType.Partner]: [ROUTES.systemSettings],
    [UserType.Dealer]: [],
    [UserType.None]: [],
  },
};

export type Route = {
  path: string;
  name: string;
  icon: string;
  disabled?: boolean;
};

export const useRoutesWithTranslations = (): {
  baseRoutes: Route[];
  userSectionRoutes: Route[];
} => {
  const {
    translate,
    translations: {
      leads: {
        list: { header: leadsHeader },
        details,
      },
      partners: {
        list: { header: partnersHeader },
      },
      users: {
        list: { header: usersHeader },
      },
      myAccount: { header: myAccountHeader },
      systemSettings: {
        list: { header: systemSettingsHeader },
      },
    },
  } = useTranslations();

  const userType = useSelector<AppState, UserType>((state) => state.User.userType);
  const { user } = useGetUser();
  const termsAndConditionsAcceptedFromToken = useSelector(selectTermsAndConditionsAcceptedFromToken);
  const termsAndConditionsAcceptedFromUser = user?.termsAndConditions?.isAccepted;
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const termsAndConditionsOk = isPartnerUser
    ? termsAndConditionsAcceptedFromToken || termsAndConditionsAcceptedFromUser
    : true;

  const allRoutes = useMemo<Record<string, Route>>(
    () => ({
      leads: {
        name: translate(leadsHeader),
        path: `/leads`,
        icon: mdiInboxOutline,
        disabled: !termsAndConditionsOk,
      },
      partners: {
        name: translate(partnersHeader),
        path: `/partners`,
        icon: mdiAccountSupervisorCircleOutline,
      },
      users: {
        name: translate(usersHeader),
        path: `/users`,
        icon: mdiAccountBoxOutline,
      },
      dealers: {
        name: translate(details.assign.dealers),
        path: `/dealers`,
        icon: mdiStorefrontOutline,
      },
      systemSettings: {
        name: translate(systemSettingsHeader),
        path: `/system-settings`,
        icon: mdiCogOutline,
      },
    }),
    [
      details.assign.dealers,
      leadsHeader,
      myAccountHeader,
      partnersHeader,
      systemSettingsHeader,
      translate,
      usersHeader,
      termsAndConditionsOk,
    ],
  );

  const baseRoutes = useMemo(
    () =>
      RoutesPerUserType.base[userType].reduce((acc, elem: Routes) => {
        const route: Route = allRoutes[elem];
        if (route) {
          acc.push(route);
        }
        return acc;
      }, [] as Route[]),
    [allRoutes, userType],
  );

  const userSectionRoutes = useMemo(
    () =>
      RoutesPerUserType.userSection[userType].reduce((acc, elem: Routes) => {
        const route: Route = allRoutes[elem];
        if (route) {
          acc.push(route);
        }
        return acc;
      }, [] as Route[]),
    [allRoutes, userType],
  );

  return {
    baseRoutes,
    userSectionRoutes,
  };
};
