import { AlertRow } from '@components/AlertCenter/AlertRows/AlertRow';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetUser } from '@services/api/users';
import { Avatar } from '@mui/material';
import Icon from '@mdi/react';
import { mdiCameraPlusOutline } from '@mdi/js/commonjs/mdi';
import { useNavigate } from 'react-router-dom';

const ProfilePictureAvatar = () => {
  const { user } = useGetUser();

  return user?.logotypeUri ? (
    <Avatar alt="Profile picture" src={user?.logotypeUri} />
  ) : (
    <Avatar>
      <Icon path={mdiCameraPlusOutline} size={1} />
    </Avatar>
  );
};

export const TermsAndConditionsAlertRow = () => {
  const {
    translate,
    translations: {
      alertCenter: { myAccount },
    },
  } = useTranslations();

  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/my-account?modal=termsAndConditions`);
  };

  return (
    <AlertRow
      Icon={<ProfilePictureAvatar />}
      title={translate(myAccount.termsAndConditions.title)}
      actionText={translate(myAccount.termsAndConditions.path)}
      subtitle={translate(myAccount.subtitle)}
      action={goToProfile}
    />
  );
};
