import { UserDto, UserType } from '@generatedTypes/data-contracts';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { CREATE, DELETE, UPDATE, USER, USERS } from '@variables/queryKeys';
import { useQueryClient } from 'react-query';
import {
  CreateUserRequestBodyWithType,
  PutUser,
  deleteDealerUser,
  deleteOperationUser,
  deletePartnerUser,
  deleteUserLogotype,
  fetchUser,
  fetchUsers,
  postUser,
  putUser,
  updateUserLogotype,
  exportUsers,
  acceptTermsAndConditions,
} from './users';
import { VIEW_TYPES, showNext } from '@components/columnLayout/utils';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SelectedUserId } from '@redux/reducers/users';
import { selectUserIdForApi } from '@redux/reducers/slices/user';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { AppState } from '@redux/reducers';

const getUserDataForApi = (selectedUserId?: string, selectedUserType?: UserType) => {
  const currentUserForApi = useSelector(selectUserIdForApi);
  const { userId } = useParams<{ userId: string }>();
  const selectedUserForApi = userId?.split(`-`).join(`/`) as SelectedUserId;
  return selectedUserId && selectedUserType
    ? (`${selectedUserType}/${selectedUserId}` as `${UserType}/${number}`)
    : selectedUserForApi || currentUserForApi;
};

export const useGetUser = (options?: { userId?: string; userType?: UserType }) => {
  const userForApi = getUserDataForApi(options?.userId, options?.userType);
  const data = useCustomQuery({
    queryKey: [USER, userForApi],
    queryFn: () => fetchUser(userForApi),
    enabled: Number(userForApi.split(`/`)[1]) > -1,
  });

  return { user: data.data || null, isLoading: data.isLoading, error: data.error, refetch: data.refetch };
};

export const useGetUsers = () => {
  const data = useCustomQuery({
    queryKey: USERS,
    queryFn: fetchUsers,
  });

  return { users: data.data || [], isLoading: data.isLoading, error: data.error, refetch: data.refetch };
};

export const useCreateUser = (options?: { onSuccess: (userKey: string) => void }) => {
  const data = useCustomMutation({
    mutationKey: [CREATE, USER],
    mutationFn: (value: CreateUserRequestBodyWithType) => postUser(value),
    onSuccess: options?.onSuccess,
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useUpdateUser = (options?: { onSuccess: () => void }) => {
  const selectedUserId = useSelector<AppState>((state) => state.Users.selectedUserID);
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [UPDATE, USER, selectedUserId],
    mutationFn: (value: PutUser) => putUser(value),
    onSuccess: () => {
      options?.onSuccess();
      queryClient.invalidateQueries(USERS);
      queryClient.invalidateQueries(USER);
      showNext(VIEW_TYPES.INFO)();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useDeleteUser = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [DELETE, USER],
    mutationFn: (userObject: UserDto | null) => {
      if (userObject?.type === UserType.Operation) {
        return deleteOperationUser(userObject?.id || -1);
      }
      if (userObject?.type === UserType.Partner) {
        return deletePartnerUser(userObject?.id || -1);
      }
      return deleteDealerUser(userObject?.id || -1);
    },
    onSuccess: (_, user) => {
      options?.onSuccess();
      queryClient.invalidateQueries(USERS);
      queryClient.removeQueries([USER, user?.id]);
      showNext(VIEW_TYPES.LIST)();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useUpdateUserProfilePicture = () => {
  const userForApi = getUserDataForApi();
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [UPDATE, USER, userForApi],
    mutationFn: updateUserLogotype,
    onSuccess: () => {
      queryClient.invalidateQueries([USER, userForApi]);
      showNext(VIEW_TYPES.INFO)();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useDeleteUserProfilePicture = () => {
  const userForApi = getUserDataForApi();
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationKey: [UPDATE, USER, userForApi],
    mutationFn: deleteUserLogotype,
    onSuccess: () => {
      queryClient.invalidateQueries([USER, userForApi]);
      showNext(VIEW_TYPES.INFO)();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};

export const useExportUsers = (options?: { onSuccess?: () => void }) => {
  const { mutate } = useCustomMutation({
    mutationFn: exportUsers,
    onSuccess: () => {
      options?.onSuccess?.();
    },
  });
  return { exportUsers: mutate };
};

export const useAcceptTermsAndConditions = (options?: { onSuccess?: () => void }) => {
  const currentUserForApi = useSelector(selectUserIdForApi);
  const queryClient = useQueryClient();
  const data = useCustomMutation({
    mutationFn: acceptTermsAndConditions,
    onSuccess: () => {
      queryClient.invalidateQueries([USER, currentUserForApi]);
      options?.onSuccess?.();
    },
  });

  return {
    isLoading: data.isLoading,
    mutate: data.mutate,
    validationErrors: data.validationErrors,
    parsedBackendError: data.parsedBackendError,
  };
};
