import { ColumnLayout } from '@components/Layout/ColumnLayout/ColumnLayout';
import { SettingsLeftNavLink } from '@components/LeftNavLink/SettingsLeftNavLink';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { AlertCenter } from '@components/AlertCenter/AlertCenter';
import { selectFeatureEnabled, selectPartnerId } from '@redux/reducers/slices/user';

export const SystemSettingsPage = () => {
  const location = useLocation();
  const {
    translate,
    translations: {
      systemSettings: { list, systemSettingsItemName, materialManagementItemName, systemMessagesItemName },
      myAccount,
    },
  } = useTranslations();
  const partnerId = useSelector(selectPartnerId);
  const systemMessagesEnabled = useSelector(selectFeatureEnabled(`system-message`));

  const isPartnerId = Boolean(partnerId);
  return (
    <ColumnLayout
      title={translate(list.header)}
      titleComponents={<AlertCenter />}
      list={[
        !isPartnerId && (
          <SettingsLeftNavLink
            key="system-settimgs"
            title={translate(systemSettingsItemName)}
            href="/system-settings"
            selected={location.pathname === `/system-settings`}
          />
        ),
        !isPartnerId && (
          <SettingsLeftNavLink
            key="material-management"
            href="/system-settings/material-management"
            title={translate(materialManagementItemName)}
            selected={location.pathname === `/system-settings/material-management`}
          />
        ),
        !isPartnerId && systemMessagesEnabled && (
          <SettingsLeftNavLink
            key="system-messages"
            href="/system-settings/system-messages"
            title={translate(systemMessagesItemName)}
            selected={location.pathname === `/system-settings/system-messages`}
          />
        ),
        isPartnerId && (
          <SettingsLeftNavLink
            key="company-information"
            href="/system-settings/company-information"
            title={translate(myAccount.listNames.partner)}
            selected={location.pathname === `/system-settings/company-information`}
          />
        ),
        isPartnerId && (
          <SettingsLeftNavLink
            key="offer"
            href="/system-settings/offer"
            title={translate(myAccount.listNames.offer)}
            selected={location.pathname === `/system-settings/offer`}
          />
        ),
        isPartnerId && (
          <SettingsLeftNavLink
            key="economy"
            href="/system-settings/economy"
            title={translate(myAccount.listNames.economy)}
            selected={location.pathname === `/system-settings/economy`}
          />
        ),
      ]}
    >
      <Outlet />
    </ColumnLayout>
  );
};
