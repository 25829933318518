import { authFetch, parseObjectToURLSearchParams } from '@services/api/utils';
import { InboxAlertDto, PartnerSettingsAlertDto, UserAccountAlertDto } from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { ALERTS } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { selectSelectedDealerId } from '@redux/reducers/slices/dealerUser';

export type AlertReturnType = InboxAlertDto | PartnerSettingsAlertDto | UserAccountAlertDto;

const fetchAlerts = async (dealerId?: number) => {
  const searchParams = parseObjectToURLSearchParams({ dealerId });
  const baseUrl = `${process.env.PROTECTED_API_URL}/alerts`;
  const url = searchParams ? `${baseUrl}?${searchParams}` : baseUrl;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });

  if (response.ok) {
    const json = await response.json();
    return json as AlertReturnType[];
  }

  throw new Error(`Failed to connect to notifications`);
};

export const useGetAlerts = (options?: { onSuccess?: () => void; onError?: () => void }) => {
  const currentDealerId = useSelector(selectSelectedDealerId);

  const { data, error, isLoading, refetch } = useCustomQuery({
    queryFn: () => fetchAlerts(currentDealerId),
    queryKey: [ALERTS, currentDealerId],
    onSuccess: () => {
      options?.onSuccess?.();
    },
    onError: () => {
      options?.onError?.();
    },
  });

  return { alerts: data, isFetchingAlerts: isLoading, alertsError: error, refetchAlerts: refetch };
};
