import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useMemo } from 'react';
import { useToggle } from '@hooks/useToggle';
import { DropdownValue } from '@components/controls/dropdown-control';
import { selectSelectedDealerId, setSelectedDealerId } from '@redux/reducers/slices/dealerUser';
import { useGetDealers } from '@services/api/dealers';
import { selectIsDealerUser } from '@redux/reducers/slices/user';

export const useFetchDealer = () => {
  const [isInitialLoading, toggleIsInitialLoading] = useToggle(true);
  const isDealerUser = useSelector(selectIsDealerUser);
  const { dealers, isLoadingDealers } = useGetDealers({ enabled: isDealerUser });
  const dispatch = useDispatch();
  const currentDealerId = useSelector(selectSelectedDealerId);

  const values: DropdownValue[] = useMemo(
    () => dealers?.map(({ id, name }) => ({ value: id, label: name ?? `` })) || [],
    [dealers],
  );

  useEffect(() => {
    if (isInitialLoading && dealers?.length) {
      const currentDealerIdExists = dealers.map(({ id }) => id).includes(Number(currentDealerId));
      isDealerUser && dealers?.length && !currentDealerIdExists && dispatch(setSelectedDealerId(dealers[0].id));
      toggleIsInitialLoading();
    }
    // mimic component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers, isDealerUser]);

  return { isDealerUser, isLoading: isLoadingDealers, values };
};
