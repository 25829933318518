import { useCallback } from 'react';
import { useGetAlerts } from '@services/api/alerts/alerts';

export const useAlertsHandlers = () => {
  const { refetchAlerts } = useGetAlerts();

  const handleAlertsUpdate = useCallback(() => {
    refetchAlerts();
  }, [refetchAlerts]);

  return {
    AlertsChanged: handleAlertsUpdate,
  };
};
