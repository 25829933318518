import { Box, Link, Stack, Typography } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';

type MenuItemContentProps = {
  name: string;
  nameVariant?: `h2` | `h3`;
  icon: string;
  active: boolean;
  disabled?: boolean;
};

type MenuItemProps = MenuItemContentProps & {
  path: string;
  onActivate?: () => void;
};

const MenuItemContent = ({ active, icon, nameVariant, name, disabled }: MenuItemContentProps) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{
      color: disabled ? `text.disabled` : active ? `white` : `text.primary`,
      '&:hover': {
        color: disabled ? `text.disabled` : active ? `text.primary` : `primary.dark`,
        backgroundColor: disabled ? `transparent` : `primary.light`,
      },
      backgroundColor: active ? `primary.dark` : `transparent`,
    }}
  >
    <Stack sx={{ width: 48, height: 48 }} justifyContent="center" alignItems="center">
      <MdiIconWrapper size="large" path={icon} />
    </Stack>
    <Typography variant={nameVariant} color="inherit">
      {name}
    </Typography>
  </Stack>
);

export const MenuItem = ({ path, onActivate, ...contentProps }: MenuItemProps) => {
  return contentProps.disabled ? (
    <Box sx={{ cursor: `default` }}>
      <MenuItemContent {...contentProps} />
    </Box>
  ) : (
    <Link href={path} onClick={onActivate} underline="none">
      <MenuItemContent {...contentProps} />
    </Link>
  );
};
