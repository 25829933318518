import { useToken } from '@services/hooks/authentication/useToken';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {
  selectIsLoggedIn,
  selectIsPartnerUser,
  selectTermsAndConditionsAcceptedFromToken,
} from '@redux/reducers/slices/user';
import { SystemMessage } from '@components/SystemMessage';
import { Box } from '@mui/material';
import { setLastLocation, setPreviousLocation } from '@redux/actions/global';
import { useGetUser } from '@services/api/users';

type AuthRouteComponentProps = {
  requireAuth?: boolean;
  children: React.ReactNode;
};

const AuthenticateRouteComponent: React.FC<AuthRouteComponentProps> = ({ requireAuth = true, children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useToken();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { user } = useGetUser();
  const termsAndConditionsAcceptedFromToken = useSelector(selectTermsAndConditionsAcceptedFromToken);
  const termsAndConditionsAcceptedFromUser = user?.termsAndConditions?.isAccepted;
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const termsAndConditionsOk =
    isLoggedIn && isPartnerUser ? termsAndConditionsAcceptedFromToken || termsAndConditionsAcceptedFromUser : true;
  const termsRestrictedPaths = [`/leads`];
  const isInTermsRestrictedPath = termsRestrictedPaths.some((restrictedPath) =>
    location.pathname.includes(restrictedPath),
  );

  useEffect(() => {
    dispatch(setLastLocation(location.pathname));
    return () => {
      dispatch(setPreviousLocation(location.pathname));
    };
  }, [dispatch, location]);

  if (!isLoggedIn && requireAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!termsAndConditionsOk && isInTermsRestrictedPath) {
    return <Navigate to="/my-account?modal=termsAndConditions" />;
  }

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        height: `100%`,
      }}
    >
      <SystemMessage />
      {children}
    </Box>
  ) as ReactElement;
};

export const AuthenticateRoute = AuthenticateRouteComponent;
