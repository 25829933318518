import {
  CreateDealerUserRequest,
  CreateOperationUserRequest,
  CreatePartnerUserRequest,
  UpdateDealerUserRequest,
  UpdateOperationUserRequest,
  UpdatePartnerUserRequest,
  UserDto,
  UserType,
} from '@generatedTypes/data-contracts';
import { initState, SelectedUserId } from '@redux/reducers/users';
import { usePost } from '../../hooks/api/useGet';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { UniversalUser } from './types';
import { fileDownloadActions, getExportListFileName } from '@utils/fileDownload';

export const fetchUsers = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/users`, {
    method: `GET`,
    mode: `cors`,
  });
  if (response.status === 200) {
    const json = await response.json();
    return json as UserDto[];
  }
  throw new Error(response.statusText);
};

const getPathBaseOnUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.Partner:
      return `partner-users`;
    case UserType.Operation:
      return `operation-users`;
    case UserType.Dealer:
      return `dealer-users`;
    default:
      return `operation-users`;
  }
};

export const fetchUser = async (id: SelectedUserId) => {
  if (id !== initState.selectedUserID) {
    const [type, userId] = id.split(`/`);
    const endpoint = getPathBaseOnUserType(type as UserType);
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/${endpoint}/${userId}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.status === 200) {
      const json = await response.json();
      return json as UniversalUser;
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const fetchOwnUser = async (userId: number | null) => {
  if (userId !== null) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/users/${userId}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.status === 200) {
      const json = await response.json();
      return json as UniversalUser;
    }
    throw new Error(response.statusText);
  }
  return null;
};

export const fetchOwnUserPartner = async (userId: number | null) => {
  if (userId !== null) {
    const response = await authFetch(`${process.env.PROTECTED_API_URL}/partner-users/${userId}`, {
      method: `GET`,
      mode: `cors`,
    });
    if (response.ok) {
      const json = await response.json();
      return json as UniversalUser;
    }
    throw new Error(response.statusText);
  }
  return null;
};

type UsersRequestTypes = CreatePartnerUserRequest | CreateOperationUserRequest | CreateDealerUserRequest;

export type CreateUserRequestBodyWithType = UsersRequestTypes & {
  userType: UserType;
};

export const postUser = async (user: CreateUserRequestBodyWithType) => {
  const endpoint = getPathBaseOnUserType(user.userType);
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/${endpoint}`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(user),
  });
  if (!response.ok) {
    const error = await getErrorFromFormRequest(response);
    throw error;
  }

  const newUserId = (await response.json()) as number;
  return `${user.userType}-${newUserId}`;
};

type UniversalUserPayload = UpdatePartnerUserRequest & UpdateOperationUserRequest & UpdateDealerUserRequest;

export type PutUser = UniversalUserPayload & {
  type: UserType;
  id: number;
};

export const putUser = ({ id, type, firstName, lastName, phoneNumber, dealerIds, isAdmin }: PutUser) => {
  const endpoint = getPathBaseOnUserType(type);
  return authFetch(`${process.env.PROTECTED_API_URL}/${endpoint}/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify({ firstName, lastName, phoneNumber, dealerIds, isAdmin }),
  }).catch(() => {
    throw new Error(`postUser error`);
  });
};

export const deleteOperationUser = async (userId: number) => {
  if (userId > 0) {
    return authFetch(`${process.env.PROTECTED_API_URL}/operation-users/${userId}`, {
      method: `DELETE`,
      mode: `cors`,
    })
      .then(async (res) => {
        if (!res.clone().ok) {
          const json = await res.clone().json();
          throw json;
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
};

export const deletePartnerUser = async (userId: number) => {
  if (userId > 0) {
    return authFetch(`${process.env.PROTECTED_API_URL}/partner-users/${userId}`, {
      method: `DELETE`,
      mode: `cors`,
    })
      .then(async (res) => {
        if (!res.clone().ok) {
          const json = await res.clone().json();
          throw json;
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
};

export const deleteDealerUser = async (userId: number) => {
  if (userId > 0) {
    return authFetch(`${process.env.PROTECTED_API_URL}/dealer-users/${userId}`, {
      method: `DELETE`,
      mode: `cors`,
    })
      .then(async (res) => {
        if (!res.clone().ok) {
          throw await res.clone().json();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
};

export interface IUpdateUserLogotype {
  userId: number;
  userType: UserType;
  profilePicture: File;
}

export const updateUserLogotype = async ({ userId, userType, profilePicture }: IUpdateUserLogotype) => {
  if (userId > 0 && userType) {
    const formData = new FormData();
    formData.append(`file`, profilePicture);

    return authFetch(`${process.env.PROTECTED_API_URL}/${userType.toLowerCase()}-users/${userId}/logotype`, {
      method: `PUT`,
      mode: `cors`,
      body: formData,
      contentType: `multipart/form-data`,
    })
      .then(async (res) => {
        if (!res.clone().ok) {
          throw await res.clone().json();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
};

export interface IDeleteUserLogotype {
  userId: number;
  userType: UserType;
}

export const deleteUserLogotype = async ({ userId, userType }: IDeleteUserLogotype) => {
  if (userId > 0 && userType) {
    return authFetch(`${process.env.PROTECTED_API_URL}/${userType.toLowerCase()}-users/${userId}/logotype`, {
      method: `DELETE`,
      mode: `cors`,
    })
      .then(async (res) => {
        if (!res.clone().ok) {
          throw await res.clone().json();
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
};

export const useUpdateUser = usePost(putUser);

export const exportUsers = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/users/export`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      const blob = await res.blob();
      if (res.ok) {
        fileDownloadActions(blob, getExportListFileName(`Användare`));
      }
    })
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

export const acceptTermsAndConditions = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/partner-users/accept-terms-and-conditions`, {
    method: `PUT`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
    })
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};
