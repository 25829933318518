import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from '@components/Card/Card';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import {
  getTermsAndConditionsPdf,
  useGetTermsAndConditions,
} from '@services/api/termsAndConditions/termsAndConditions';
import Divider from '@mui/material/Divider';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { mdiFileOutline } from '@mdi/js';
import { ListItem } from '@components/ListMui/ListItem';
import { useAcceptTermsAndConditions, useGetUser } from '@services/api/users';
import { Checkbox } from '@components/controls/react-hook-form-friendly/dumb/Checkbox/Checkbox';
import { useToggle } from '@hooks/useToggle';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { LinkButton } from '@components/button/LinkButton';

type TermsAndConditionsModalContentProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TermsAndConditionsModalContent = ({ onClose, isOpen }: TermsAndConditionsModalContentProps) => {
  const {
    translate,
    translations: {
      termsAndConditionsModal,
      common: {
        buttons: { cancel },
      },
    },
  } = useTranslations();

  const { termsAndConditions, isTermsAndConditionsLoading } = useGetTermsAndConditions();
  const { mutate: acceptTermsAndConditions, isLoading: isTermsAndConditions } = useAcceptTermsAndConditions({
    onSuccess: onClose,
  });
  const { user } = useGetUser();
  const termsAccepted = !!user?.termsAndConditions?.isAccepted;

  const [termsAcceptedCheckbox, toggleTermsAcceptedCheckbox] = useToggle(termsAccepted);

  if (isTermsAndConditionsLoading) {
    return null;
  }

  return (
    <ActionInformationModal
      header={translate(termsAndConditionsModal.header)}
      isOpen={isOpen}
      confirmText={translate(termsAndConditionsModal.submitButton)}
      onConfirm={acceptTermsAndConditions}
      cancelText={translate(cancel)}
      onClose={onClose}
      submitDisabled={!termsAcceptedCheckbox || isTermsAndConditions || termsAccepted}
    >
      <Card sx={{ border: `none`, margin: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h2">{translate(termsAndConditionsModal.contentHeader)}</Typography>
          <Typography>{translate(termsAndConditionsModal.contentDescription)}</Typography>
          <Divider />
          <RichTextPreview richTextAsHtml={termsAndConditions?.termsAndConditions ?? ``} />
          <Typography variant="h3">{translate(termsAndConditionsModal.download)}</Typography>
          <LinkButton variant="text" onClick={getTermsAndConditionsPdf}>
            <Stack direction="row" spacing={2}>
              <MdiIconWrapper path={mdiFileOutline} />
              <Typography color={(theme) => theme.palette.rexelEnergy.ctaBlue}>
                {translate(termsAndConditionsModal.title)}
              </Typography>
            </Stack>
          </LinkButton>
          <Divider />
          <Box sx={{ marginLeft: 2 }}>
            <Checkbox
              label={translate(termsAndConditionsModal.acceptTerms)}
              isLabelOnRight
              checked={termsAcceptedCheckbox}
              name="termsAccepted"
              onChange={toggleTermsAcceptedCheckbox}
              value="termsAccepted"
              isDisabled={termsAccepted}
            />
          </Box>
          <Divider />
        </Stack>
      </Card>
    </ActionInformationModal>
  );
};

export const TermsAndConditionsModal = () => {
  const {
    translate,
    translations: { termsAndConditionsModal },
  } = useTranslations();

  const { user } = useGetUser();
  const { isModalOpen, openModal, closeModal } = useQueryParamsModal(`termsAndConditions`);

  const termsAcceptedDate = user?.termsAndConditions?.acceptedAtUtc;

  const getDateFormatted = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, `0`)}-${String(date.getDate()).padStart(2, `0`)}`;
  };

  return (
    <>
      <ListItem
        icon={mdiFileOutline}
        title={
          <Stack direction="row" justifyContent="space-between">
            <Typography>{translate(termsAndConditionsModal.title)}</Typography>
            <Typography>
              {termsAcceptedDate
                ? getDateFormatted(new Date(termsAcceptedDate))
                : translate(termsAndConditionsModal.notApproved)}
            </Typography>
          </Stack>
        }
        onClick={openModal}
      />
      {isModalOpen && <TermsAndConditionsModalContent isOpen={isModalOpen} onClose={closeModal} />}
    </>
  );
};
