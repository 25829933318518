import { authFetch } from '@services/api/utils';
import { TermsAndConditionsDto } from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { TERMS_AND_CONDITIONS } from '@variables/queryKeys';
import { fileDownloadActions } from '@utils/fileDownload';

export const getTermsAndConditions = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/terms-and-conditions`, {
    method: `GET`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw new Error(`Contact data fetch failed`);
  }
  const json = await response.json();
  return json as TermsAndConditionsDto;
};

export const getTermsAndConditionsPdf = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/terms-and-conditions-pdf`, {
    method: `GET`,
    mode: `cors`,
  });
  const blob = await response.blob();
  if (response.ok) {
    fileDownloadActions(blob, `MyEnergy - Villkor för användning.xlsx`);
  }
};

export const useGetTermsAndConditions = () => {
  const { data, isLoading } = useCustomQuery({
    queryKey: [TERMS_AND_CONDITIONS],
    queryFn: () => getTermsAndConditions(),
  });

  return { termsAndConditions: data, isTermsAndConditionsLoading: isLoading };
};
