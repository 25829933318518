import { ListItemIcon, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

type AlertRowProps = {
  Icon: React.ReactNode;
  action: () => void;
  title: string;
  actionText: string;
  subtitle: string;
};

export const AlertRow: React.FC<AlertRowProps> = ({ Icon, action, actionText, subtitle, title }) => (
  <Stack
    sx={(theme) => ({
      margin: theme.spacing(1, 1, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    })}
    spacing={1}
  >
    <MenuItem
      onClick={action}
      sx={(theme) => ({
        height: theme.spacing(8.125),
        margin: 0,
        padding: 0,
      })}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <ListItemIcon
          sx={(theme) => ({
            height: theme.spacing(6),
            width: theme.spacing(6),
            maxWidth: theme.spacing(6),
            margin: `0 !important`,
          })}
        >
          {Icon}
        </ListItemIcon>
        <Stack width={(theme) => theme.spacing(27.75)}>
          <Stack direction="row" flexWrap="wrap" width="100%">
            <Typography variant="h5">{title}</Typography>
            <Typography variant="h5" color={(theme) => theme.palette.rexelEnergy.ctaBlue}>
              {actionText}
            </Typography>
          </Stack>
          <Typography variant="subtitle1" sx={(theme) => ({ fontSize: theme.typography.h6.fontSize })}>
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </MenuItem>
    <Divider sx={{ width: `100%`, margin: 0 }} />
  </Stack>
);
