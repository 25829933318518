import { SolarEnergyProductionDto } from '@generatedTypes/data-contracts';
import { authFetch, parseObjectToStringValues } from '@services/api/utils';
import { usePost } from '@services/hooks/api/useGet';
import { SolarEnergyProjectMountingMaterialManufacturersProps } from '@services/api/solarEnergyProjects/solarEnergyProjectMountingMaterialManufacturers';

export type SolarEnergyProjectPatchProductionProps = {
  latitude: number;
  longitude: number;
  numberOfSolarPanels: number;
  angle: number;
  roofId?: number;
  solarPanelManufacturerId?: number;
  solarPanelWidthId?: number;
  solarPanelHeightId?: number;
  customSolarPanelWidth?: number;
  customSolarPanelHeight?: number;
  customSolarPanelOutputPower?: number;
};

export const fetchSolarEnergyProjectPatchProduction = async (
  props: SolarEnergyProjectPatchProductionProps,
  signal?: AbortSignal,
): Promise<SolarEnergyProductionDto> => {
  const url =
    `${process.env.PROTECTED_API_URL}/solar-energy-projects/solar-energy-production?` +
    new URLSearchParams(
      parseObjectToStringValues<Record<keyof SolarEnergyProjectMountingMaterialManufacturersProps, string>>({
        latitude: props.latitude,
        longitude: props.longitude,
        numberOfSolarPanels: props.numberOfSolarPanels,
        angle: props.angle,
        roofId: props.roofId,
        solarPanelManufacturerId: props.solarPanelManufacturerId,
        solarPanelWidthId: props.solarPanelWidthId,
        solarPanelHeightId: props.solarPanelHeightId,
        customSolarPanelWidth: props.customSolarPanelWidth,
        customSolarPanelHeight: props.customSolarPanelHeight,
        customSolarPanelOutputPower: props.customSolarPanelOutputPower,
      }),
    );
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
    signal,
  });
  const json = await response.json();
  if (response.ok) {
    return json as SolarEnergyProductionDto;
  }
  return { yearlyProduction: 0, peakPower: 0, yearlyProductionInkWh: 0 };
};

export const useFetchSolarEnergyProjectPatchProduction = usePost(fetchSolarEnergyProjectPatchProduction);
