import { ValidationCodes } from '@components/controls/validations';
import { getKeys } from '@utils/objectAccessors';
import { getOrRefreshToken, resetTokenInStorage } from '@services/hooks/authentication/utils';

export const getOptionsWithStandardizedBody = (options: MyRequestInit): RequestInit => {
  const { body, allowEmptyFields } = options;
  if (body) {
    if (typeof body === `string`) {
      const bodyObject = JSON.parse(body);
      const nonEmptyNamesBody = Object.getOwnPropertyNames(bodyObject).reduce(
        (acc, key) => {
          const value = bodyObject[key];
          if ((value !== undefined && value !== null && value !== ``) || allowEmptyFields) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, unknown>,
      );
      options.body = JSON.stringify(nonEmptyNamesBody);
    }
  }
  return options;
};

export interface MyRequestInit extends Omit<RequestInit, `headers`> {
  headers?: Record<string, string>;
  contentType?: string;
  accept?: string;
  signal?: AbortSignal;
  allowEmptyFields?: boolean;
}

export const authFetch = async (url: RequestInfo, options: MyRequestInit) => {
  const tokenData = await getOrRefreshToken();
  const contentType = options?.contentType;
  const accept = options?.accept;
  const headers = new Headers();

  if (!contentType) {
    headers.append(`Content-Type`, `application/json`);
  }
  if (!accept) {
    headers.append(`Accept`, `application/json`);
  }

  if (tokenData?.token) {
    headers.append(`Authorization`, `Bearer ${tokenData.token}`);
  }

  const response = await fetch(url, {
    ...getOptionsWithStandardizedBody(options),
    headers,
    signal: options.signal,
    credentials: `include`,
  });
  if (response.status === 401) {
    resetTokenInStorage();
  }
  return response;
};

export const VALIDATION_ERROR = `VALIDATION_ERROR`;
export const PARSED_VALIDATION_ERROR = `PARSED_VALIDATION_ERROR`;
export const VALIDATION_ERROR_CODE = `ValidationError`;
export type BackendValidationResult = {
  name: typeof VALIDATION_ERROR;
  errors: {
    code: typeof VALIDATION_ERROR_CODE;
    message: string;
    path: string;
    constraint: ValidationCodes;
  }[];
};
export function isBackendValidationResult(result: Error | BackendValidationResult): result is BackendValidationResult {
  const isValidationError = result.name === VALIDATION_ERROR;
  const hasErrors =
    Object.hasOwn(result, `errors`) &&
    Array.isArray((result as BackendValidationResult).errors) &&
    (result as BackendValidationResult).errors.length > 0;
  const errorsHasPath = Object.hasOwn((result as BackendValidationResult).errors[0], `path`);
  return isValidationError && hasErrors && errorsHasPath;
}
export const getErrorFromFormRequest = async (response: Response) => {
  if (response.status === 400) {
    const responseData = await response.json();
    if (Object.hasOwn(responseData, `errors`)) {
      const { errors } = responseData;
      if (Array.isArray(errors)) {
        return {
          name: VALIDATION_ERROR,
          errors,
        };
      }
    }
  }
  return await response.json();
};

export const parseObjectToStringValues = <T extends Record<string, string | string[]>>(obj: Record<string, unknown>) =>
  getKeys(obj).reduce<Partial<T>>((acc, key) => {
    return !obj[key]
      ? acc
      : {
          ...acc,
          [key]: Array.isArray(obj[key]) ? (obj[key] as unknown[]).map((el) => String(el)) : String(obj[key]),
        };
  }, {});

export const parseObjectToURLSearchParams = (obj: Record<string, unknown>) => {
  const parsedToString = parseObjectToStringValues(obj);
  return Object.keys(parsedToString).reduce((acc, key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((el) => acc.append(key, el));
    } else {
      acc.append(key, String(value));
    }
    return acc;
  }, new URLSearchParams());
};
