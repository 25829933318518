import { AlertReturnType } from '@services/api/alerts/alerts';
import {
  alertIsInboxAlertDto,
  alertIsPartnerSettingsAlertDto,
  alertIsUserAccountAlertDto,
} from '@components/AlertCenter/AlertRows/alertsAssertsFunctions';
import { UserAccountAlertRow } from '@components/AlertCenter/AlertRows/userAccount/UserAccountAlertRow';
import { InboxAlertRow } from '@components/AlertCenter/AlertRows/inbox/InboxAlertRow';
import { PartnerSettingsAlertRow } from '@components/AlertCenter/AlertRows/partnerSettings/PartnerSettingsAlertRow';

const MappedAlert = (alert: AlertReturnType): React.ReactNode => {
  if (alertIsInboxAlertDto(alert)) {
    return <InboxAlertRow alert={alert} />;
  }

  if (alertIsUserAccountAlertDto(alert)) {
    return <UserAccountAlertRow alert={alert} />;
  }

  if (alertIsPartnerSettingsAlertDto(alert)) {
    return <PartnerSettingsAlertRow alert={alert} />;
  }

  console.error(`AlertRow: alertScope ${(alert as AlertReturnType).scope} is not supported`);

  return null;
};

export const Alerts = ({ alerts }: { alerts: AlertReturnType[] }) => (
  <>
    {alerts.map((alert, index) => (
      <MappedAlert key={`${alert.scope}.${alert.alertType}.${index}`} {...alert} />
    ))}
  </>
);
