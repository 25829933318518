import { Link } from 'react-router-dom';

export type NavLinkItemProps = {
  name: string;
  path: string;
  active: boolean;
  profileItem?: boolean;
  onActivate?: () => void;
  disabled?: boolean;
};
export const NavLinkItem: React.FC<NavLinkItemProps> = ({
  name,
  path,
  active,
  profileItem = false,
  onActivate,
  disabled,
}) => (
  <div
    className={`nav-item${active ? ` active` : ``}${profileItem ? ` profile-item` : ``}${disabled ? ` disabled` : ``}`}
  >
    {disabled ? (
      <span className="nav-link">{name}</span>
    ) : (
      <Link className="nav-link" to={path} onClick={onActivate}>
        {name}
      </Link>
    )}
  </div>
);
